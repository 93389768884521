import React from 'react'
import {Botoes,Input} from '../utilitarios'
import { Container } from './styles'

const GroupLogin = (props)=>{

    return(
        <Container>
            <Input placeholder='LOGIN' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
             PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
             letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px"  bgcolor="#F2F2F2"
             borderBottom="1px solid #C6C6C6" marginTop="50px" type="text" onKeyPress={props.keyEnter}
             value={props.username} onChange={e=>{props.setUsername(e.target.value)}}
            />
            <Input placeholder='SENHA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
             PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
             letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px" bgcolor="#F2F2F2"
             borderBottom="1px solid #C6C6C6" marginTop="20px" type="password" onKeyPress={props.keyEnter}
             value={props.password} onChange={e=>{props.setPassword(e.target.value)}}
            />
            <Botoes fontFamily="Montserrat" bgcolor="#F2F2F2" borderBottom="1px solid #C6C6C6" TextAlign="center" marginTop="50px" 
            width="140px" FontSize="14px" fontWight="300" color="#656565" letterSpacing="0.27em" lineHeight="17px" Activecolor="#3997EE"
            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.Logar()}>
                ENTRAR
            </Botoes>
            <Botoes fontFamily="Montserrat" bgcolor="#F2F2F2" borderBottom="1px solid #C6C6C6" TextAlign="center" marginTop="50px"
            width="120px" FontSize="14px" fontWight="300" color="#656565" lineHeight="17px" Activecolor="#3997EE" 
            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.abrirModal()}>
                Esqueceu a senha ?
            </Botoes>
        </Container>
    )
}

export default GroupLogin