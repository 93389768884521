import React, { useEffect, useState } from 'react'
import { getNivelPermissaoCookie , Auth_login , getNomeNivelPermissao, getNomeCliente, getAreas, getIdCliente } from '../../utils'
import { Container, Content, ContentGif } from './styles'
import Cookie from "js-cookie"
import HeaderHomeClienteAdmcliente from '../../componentes/header-home-cliente-admcli'
import toast from 'react-hot-toast';
import ConnectCliente from '../../apis/cliente'
import ConnectLogin from '../../apis/login'
import { Route } from 'react-router-dom'
import Dashboard from '../dashboard'
import SelectHomeClienteAdmcliente from '../../componentes/select-home-cliente-admcli'
import gif from '../../imagens/CarregandoDashPSAi.gif'
import { Img, Titulo } from '../../componentes/utilitarios'
import ModalTrocarSenha from '../../componentes/modal-trocarsenha'

const HomeCliente = (props)=>{

    const serviceCliente = new ConnectCliente()
    const serviceLogin = new ConnectLogin()

    const [tokenEmbedded,setTokenEmbedded]=useState(null)
    const [painelAtivo,setPaineilAtivo]=useState(null)
    const [areaAtiva,setAreaAtiva]=useState(null)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [paineisUsuario]=useState(null);
    const [painelRenderizado,setPainelRenderizado]=useState(null)
    const [areas]=useState(getAreas())
    const [dicas]=useState(
        ['O menu principal de Dashboards está na parte superior esquerda da tela',
         'Os Dashboards estão divididos por áreas de negócio',
         'O menu na parte superior direita refere-se as configurações do usuário'
        ])
    const [dicaAtual,setDicaAtual]=useState(dicas[0])

    useEffect(()=>{
        if(painelRenderizado!=null){
            gerarTokenEmbedded(painelRenderizado.idpaineisusuario,
                               painelRenderizado.idpainel,
                               painelRenderizado.idAreanegocio)
        }
    },[painelRenderizado])

    useEffect(()=>{
        const nivelpermissao = getNivelPermissaoCookie()
        if(!Auth_login()){
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
        else if(nivelpermissao===0){
            window.location.href="/adm"
        }
        else if(nivelpermissao===1){
            window.location.href="/admcliente"
        }
    },[])

    useEffect(async()=>{
        try{
            let result = await serviceLogin.verificarVivo()
            if(result.status===401){
                Cookie.remove('connect.sid')
                Cookie.remove('usuario.sid')
                Cookie.remove('perm.sid')
                Cookie.remove('areas.sid')
                Cookie.remove('paineis.sid')
                window.location.href="/"
            }
        }
        catch(error){
            //console.log(error)
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
        
    },[HomeCliente])

    useEffect(async()=>{
        try{
            let index = 0
            while(tokenEmbedded===null){
                await delay(5000);
                if(dicas.length===index+1){
                    index = 0
                }
                else{
                    index = index + 1
                }
                setDicaAtual(dicas[index])
            }
        }
        catch(error){
            //console.log(error)
        }
        
    },[])

    const deslogar = async() =>{
        try{
            await serviceLogin.deslogar()
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
        catch(error){
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
    }

    useEffect(()=>{
        if(tokenEmbedded!=null){
            props.history.push('/cliente/dashboard')
        }
    },[tokenEmbedded])

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const carregamentoGif = () =>{
        if(tokenEmbedded===null){
            return(
                <ContentGif>
                    <Img src={gif} width="200px" height="200px"/>
                    <Titulo TextAlign="center" FontSize="16px" FontFamily="Montserrat" color="#3997EE" letterSpacing="0.17em" lineHeight="19px" 
                    FontWeight="400"  borderBottom="0.25px solid #3997EE" marginTop="50px">
                        Dicas
                    </Titulo>
                    <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131"  lineHeight="17px" 
                    FontWeight="400" marginTop="20px">
                        {dicaAtual}
                    </Titulo>
                </ContentGif>
            )
        }
    }

    const gerarTokenEmbedded = async(idpaineisusuario,idpainel,idAreanegocio)=>{
        try{
            setPaineilAtivo(idpainel)
            setAreaAtiva(idAreanegocio)
            let url = "?idpaineisusuario="+idpaineisusuario
            let result = await serviceCliente.gerarToken(url) 
            if(result.status===200){
                setTokenEmbedded({
                    "accessToken":result.data.accessToken,
                    "embedUrl":result.data.embedUrl,
                    "reportId":result.data.reportId,
                    "idpainel":idpainel,
                    "idAreanegocio":idAreanegocio,
                    "idpaineisusuario":idpaineisusuario
                })
            }
        }
        catch(error){
            if(error.response.status===401){
                toast.error("Sessao expirada, refaça o login")
                Cookie.remove('connect.sid')
                Cookie.remove('usuario.sid')
                Cookie.remove('perm.sid')
                Cookie.remove('areas.sid')
                Cookie.remove('paineis.sid')
                window.location.href="/"
            }
            else{
                toast.error(error.response.data.msg)
            }
        }
    }

    const abrirModalTrocarSenha = () =>{
        setModalIsOpen(!modalIsOpen)
    }

    return(
        <Container>
            <HeaderHomeClienteAdmcliente deslogar={deslogar} nomeusuario={getNomeCliente()} nivelpermissao={getNomeNivelPermissao()} 
                TrocarSenha={abrirModalTrocarSenha} SelectHomeClienteAdmcliente={SelectHomeClienteAdmcliente}
                painelAtivo={painelAtivo} areas={areas} gerarTokenEmbedded={gerarTokenEmbedded}
                areaAtiva={areaAtiva} paineisUsuario={paineisUsuario}/>
            <Content>
                {carregamentoGif()}
                <Route path="/cliente/dashboard" render={(props) => <Dashboard {...props} tokenEmbedded={tokenEmbedded}
                                                                    setTokenEmbedded={setTokenEmbedded}
                                                                    painelRenderizado={painelRenderizado}
                                                                    setPainelRenderizado={setPainelRenderizado}/>}/>
            </Content>
            {/*<FooterHomeClienteAdmcliente painelAtivo={painelAtivo} gerarTokenEmbedded={gerarTokenEmbedded}/>*/}
            <ModalTrocarSenha
                setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
            />
        </Container>
    )
}

export default HomeCliente