import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing:border-box;//Evita q bordar ultrapassem o tamanho da tela
        font-family: 'Montserrat', sans-serif;
    }

    html,border-style,#root{
        height: 100vh;
    }

    *,button,input{
        border:0;
        outline:0;
    }

    button,a{
        cursor: pointer;
    }
`;