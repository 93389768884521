import React, { useState , useEffect } from 'react'
import { getNivelPermissaoCookie , Auth_login } from '../../utils'
import Cookie from "js-cookie"

const HomeAdmCliente = (props)=>{

    useEffect(()=>{
        const nivelpermissao = getNivelPermissaoCookie()
        if(!Auth_login()){
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
        else if(nivelpermissao===0){
            window.location.href="/adm"
        }
        else if(nivelpermissao===2){
            window.location.href="/cliente"
        }
    },[])

    return(
        <div>
            OI ADM Cliente
        </div>
    )
}

export default HomeAdmCliente