import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: white;
    display:flex;
    flex-direction: row;
`;


export const Card = styled.div`
    width: 50%;
    height: 100%;
    background: white;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`