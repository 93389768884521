import React, { useEffect, useState } from 'react'
import ConnectAreaNegocio from '../../apis/adm/areanegocio'
import List from '../../componentes/lista'
import { Container , Card } from './styles'
import toast from 'react-hot-toast';
import CRUDAreaNegocio from '../../componentes/crud-area-negocio';


const AreaNegocio = (props)=>{

    const service = new ConnectAreaNegocio()
    const [areasNegocio,setAreasNegocio]=useState([])
    const [offset,setOffset]=useState(0)
    const [paginaAtual,setPaginaAtual]=useState(1)
    const [maximoPaginas,setMaximoPaginas]=useState(1)
    const [idNegocio,setIdNegocio]=useState(-1)
    const [abrirComponente,setAbrirComponente]=useState(false)
    const [id,setId]=useState("")
    const [nome,setNome]=useState("")
    const [nomeOperacao,setNomeOperacao]=useState("")

    useEffect(async()=>{
        if(props.paginaAtiva===null){
            window.location.href="/adm"
            return 0
        }
        await ConsultarAreaNegocio()
    },[])

    useEffect(async()=>{
        setAreasNegocio([])
        await ConsultarAreaNegocio()
    },[offset])

    useEffect(()=>{
        if(paginaAtual>maximoPaginas){
            setPaginaAtual(1)
            setOffset(0)
        }
    },[maximoPaginas])


    useEffect(()=>{
        if(idNegocio===null){
            setId("")
            setNome("")
            setNomeOperacao("INSERÇÂO")
            setAbrirComponente(true)
            return 0
        }
        for(let i in areasNegocio){
            if(areasNegocio[i].id === idNegocio){
                setId(areasNegocio[i].id)
                setNome(areasNegocio[i].nome)
                setNomeOperacao("ALTERAÇÃO")
                setAbrirComponente(true)
                return 0
            }
        }
    },[idNegocio])

    const validarInfos = () =>{
        if(nome===""){
            toast.error("Informe o nome da área de negócio")
            return false
        }
        return true
    }

    const ConsultarAreaNegocio = async()=>{
        try{
            let url = "idareanegocio=null&nomeareanegocio=null&ativo=true&offset="+offset
            let result = await service.consultarAreaNegocio(url)
            if(result.status===200){
                setAreasNegocio([])
                let arrendondar = Math.ceil(result.data.count/6);
                if(arrendondar===0){
                    arrendondar=1
                }
                setMaximoPaginas(arrendondar)
                setAreasNegocio(result.data.data)
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CriarAreaNegocio = async()=>{
        try{
            if(validarInfos()){
                let result = await service.criarAreaNegocio({
                    "nomeareanegocio":nome
                })
                if(result.status===201){
                    toast.success("Inserido nova area de negocio")
                    await ConsultarAreaNegocio()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const DeletarAreaNegocio = async()=>{
        try{
            if(validarInfos()){
                let url = "idareanegocio="+id
                let result = await service.deleteAreaNegocio(url)
                if(result.status===200){
                    toast.success("Deletado area de negocio")
                    await ConsultarAreaNegocio()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const AlterarAreaNegocio = async()=>{
        try{
            if(validarInfos()){
                let result = await service.alterarAreaNegocio({
                    "nomeareanegocio":nome,
                    "idareanegocio":id
                })
                if(result.status===200){
                    toast.success("Alterado area de negocio")
                    await ConsultarAreaNegocio()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CancelarOperacao = () =>{
        setAbrirComponente(false)
        setId("")
        setNome("")
        setNomeOperacao("")
        setIdNegocio(-1)
    }


    return(
        <Container>
            <Card>
                <List data={areasNegocio} isCrudUsuario={false} coluna1="ID" coluna2="NOME" nomePagina="ÁREA DE NEGOCIOS"
                  paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} 
                  paginaFinal={maximoPaginas} offset={offset} setOffset={setOffset}
                  setAbrirCrud={setIdNegocio} />
            </Card>
            <Card>
                {abrirComponente &&(
                    <CRUDAreaNegocio id={id} setId={setId} nomeOperacao={nomeOperacao}
                     nome={nome} setNome={setNome} 
                     CancelarOperacao={CancelarOperacao} AlterarAreaNegocio={AlterarAreaNegocio}
                     DeletarAreaNegocio={DeletarAreaNegocio} CriarAreaNegocio={CriarAreaNegocio}/>
                )}
            </Card>
        </Container>
    )
}

export default AreaNegocio