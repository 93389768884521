import ApiService from "../../index";

class ConnectPaineisCliente extends ApiService{
    constructor(){
        super('/api/v1/adm/paineisCliente')
    }
    
    async consultarPaineisCliente(query){
        return await this.get("/?"+query)
    }

    async listPaineisCliente(query){
        return await this.get("/list?"+query)
    }

    async criarPaineisCliente(obj){
        return await this.post("/",obj)
    }

    async alterarPaineisCliente(obj){
        return await this.put("/",obj)
    }

    async deletarPaineisCliente(query){
        return await this.delete("/?"+query)
    }

    async deletarTodasPaineisCliente(query){
        return await this.delete("/delete-vinculacao-cliente?"+query)
    }

}

export default ConnectPaineisCliente