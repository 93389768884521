import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    border-right: 1px solid #C6C6C6;
`;

export const Tabela = styled.table`
    width: 90%;
    height: 90%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    border-collapse: collapse;
    text-align: center;
    
`

export const THead = styled.thead`
    width: 100%;
    height: 2.5%;
    border-bottom: 2px solid #C6C6C6;
`

export const Card = styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props=>props.flexDirection};
    cursor: ${props=>props.cursor};
    &:hover{
        background-color: ${props=>props.Hoverbgcolor};
        opacity:${props=>props.Hoveopacity};
        color: ${props=>props.Hovercolor};
        font-weight: ${props=>props.HoverFontWeight};
    }

`

export const CardHeader = styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: ${props=>props.flexDirection};

`

export const Border = styled.div`
    width: 90%;
    border-bottom: ${props=>props.borderBottom};
    border-top: ${props=>props.borderTop};
`

export const TBody = styled.tbody`
    width: 100%;
    height: 88%;
    margin-top: 2%;
    border-bottom: 2px solid #C6C6C6;
`
export const TFoot = styled.tfoot`
    width: 100%;
    height: 3.5%;
    margin-top: 1%;
    border-bottom: 2px solid #C6C6C6;
    display: flex;
    justify-content: center;
`

export const Tr = styled.tr`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16%;
    border-width:60%;
`

export const Th = styled.th`
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.27em;
    color: #656565;
    text-align: center;
`

export const Td = styled.td`
    width: 100%;
    height: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.27em;
    color: #656565;
    text-align: center;
`