import styled from "styled-components";

export const Titulo = styled.label`
    text-align: ${props=>props.TextAlign};
    font-size: ${props=>props.FontSize};
    margin: ${props=>props.margin};
    font-weight:  ${props=>props.FontWeight};
    border-bottom:  ${props=>props.borderBottom};
    font-family:${props=>props.FontFamily};
    color: ${props=>props.color};
    letter-spacing: ${props=>props.letterSpacing};
    line-height: ${props=>props.lineHeight};
    padding-top: ${props=>props.paddingTop};
    padding-bottom: ${props=>props.paddingBottom};
    padding-left: ${props=>props.paddingLeft};
    padding-right: ${props=>props.paddingRight};
    margin-bottom: ${props=>props.marginBottom};
    margin-top: ${props=>props.marginTop};
    margin-right: ${props=>props.marginRight};
    margin-left: ${props=>props.marginLeft};
    height: ${props=>props.height};
`;


export const Botoes = styled.button`
    margin: ${props=>props.margin};
    text-align: ${props=>props.TextAlign};
    font-size: ${props=>props.FontSize};
    border-radius: ${props=>props.borderRadius};
    width:${props=>props.width};
    height:${props=>props.height};
    color:${props=>props.color};
    background-color:${props=>props.bgcolor};
    font-family:${props=>props.fontFamily};
    border-bottom: ${props=>props.borderBottom};
    margin-top: ${props=>props.marginTop};
    margin-bottom: ${props=>props.marginBottom};
    font-weight:  ${props=>props.fontWight};
    letter-spacing: ${props=>props.letterSpacing};
    line-height: ${props=>props.lineHeight};
    margin-left: ${props=>props.marginLeft};
    margin-right: ${props=>props.marginRight};
    &:hover{
        background-color: ${props=>props.Hoverbgcolor};
        opacity:${props=>props.Hoveopacity};
        color: ${props=>props.Hovercolor};
        font-weight: ${props=>props.HoverFontWeight};
    }
    &:active{
        background-color: ${props=>props.Activebgcolor};
        color: ${props=>props.Activecolor};
        opacity:${props=>props.Activeopacity};
        font-weight: ${props=>props.ActiveFontWeight};
    }
`;

export const Input = styled.input`
    text-align: ${props=>props.TextAlign};
    font-size: ${props=>props.FontSize};
    border-radius: ${props=>props.borderRadius};
    border:${props=>props.border};
    width:${props=>props.width};
    height:${props=>props.height};
    font-family: ${props=>props.fontFamily};
    font-weight: ${props=>props.fontWight};
    letter-spacing: ${props=>props.letterSpacing};
    line-height: ${props=>props.lineHeight};
    color: ${props=>props.Color};
    background-color: ${props=>props.bgcolor};
    border-bottom: ${props=>props.borderBottom};
    margin-top: ${props=>props.marginTop};
    cursor: ${props=>props.cursor};
    &::placeholder{
        font-family: ${props=>props.PlacefontFamily};
        font-weight: ${props=>props.PlacefontWight};
        font-size: ${props=>props.PlacefontSize};
        letter-spacing: ${props=>props.PlaceletterSpacing};
        line-height: ${props=>props.PlacelineHeight};
        text-align: ${props=>props.PlaceTextAlign};
        color: ${props=>props.PlaceColor};
    }
`;


export const Img = styled.img`
    width: ${props=>props.width};
    display: ${props=>props.display};
    justify-content: ${props=>props.JustifyContent};
    align-items: ${props=>props.AlignItems};
    margin-top: ${props=>props.marginTop};
    cursor: ${props=>props.cursor};
    opacity: ${props=>props.opacity};;
    &:hover{
        cursor: ${props=>props.Hovercursor};
        background-color: ${props=>props.Hoverbgcolor};
        opacity:${props=>props.Hoveopacity};
        color: ${props=>props.Hovercolor};
        font-weight: ${props=>props.HoverFontWeight};
    }
    &:active{
        background-color: ${props=>props.Activebgcolor};
        color: ${props=>props.Activecolor};
        opacity:${props=>props.Activeopacity};
        font-weight: ${props=>props.ActiveFontWeight};
    }
`

export const Select = styled.select`
    text-align: ${props=>props.TextAlign};
    font-size: ${props=>props.FontSize};
    border-radius: ${props=>props.borderRadius};
    border:${props=>props.border};
    width:${props=>props.width};
    height:${props=>props.height};
    font-family: ${props=>props.fontFamily};
    font-weight: ${props=>props.fontWight};
    letter-spacing: ${props=>props.letterSpacing};
    line-height: ${props=>props.lineHeight};
    color: ${props=>props.Color};
    background-color: ${props=>props.bgcolor};
    border-bottom: ${props=>props.borderBottom};
    margin-top: ${props=>props.marginTop};
    cursor: ${props=>props.cursor};
    -webkit-border-radius: 0;
    border: 0;
    outline: 1px solid #ccc;
    outline-offset: -1px;
    -moz-appearance: none;
`

export const Option = styled.option`
    text-align: ${props=>props.TextAlign};
    font-size: ${props=>props.FontSize};
    border-radius: ${props=>props.borderRadius};
    border:${props=>props.border};
    width:${props=>props.width};
    height:${props=>props.height};
    font-family: ${props=>props.fontFamily};
    font-weight: ${props=>props.fontWight};
    letter-spacing: ${props=>props.letterSpacing};
    line-height: ${props=>props.lineHeight};
    color: ${props=>props.Color};
    background-color: ${props=>props.bgcolor};
    border-bottom: ${props=>props.borderBottom};
    margin-top: ${props=>props.marginTop};
    cursor: ${props=>props.cursor};
`