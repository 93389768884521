import ApiService from "../index";

class ConnectCliente extends ApiService{
    constructor(){
        super('/api/v1/cliente')
    }
    
    async gerarToken(query){
        return await this.get("/gerarToken"+query)
    }

    async paineisUsuario(){
        return await this.get("/paineisclienteusuario")
    }

}

export default ConnectCliente