import { useState,useEffect } from "react";
import { StyledModal , Container } from './styles'
import {Botoes,Input, Titulo} from '../utilitarios'
import ModalConfirmacao from "../modal-confirmacao";
import toast from 'react-hot-toast';
import ConnectLogin from "../../apis/login";
import Cookie from "js-cookie"

const ModalTrocarSenha = (props)=>{

    const [opacity, setOpacity] = useState(0);
    const [senhaAntiga,setSenhaAntiga] =useState(null)
    const [novaSenha,setNovaSenha] =useState(null)
    const [confSenha,setConfSenha] =useState(null)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [resposta,setResposta] =useState(null)

    const service = new ConnectLogin()

    function toggleModal(e) {
        setOpacity(0);
        limparCampos()
        props.setModalIsOpen(!props.modalIsOpen);
      }
    
    function afterOpen() {
      setTimeout(() => {
        limparCampos()
        setOpacity(1);
      }, 50);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 50);
        limparCampos()
      });
    }

    const limparCampos = () =>{
        setSenhaAntiga("")
        setNovaSenha("")
        setConfSenha("")
        setResposta(null)
    }

    useEffect(()=>{
        limparCampos()
    },[])

    useEffect(async()=>{
        try{
            if(resposta==false){
                limparCampos()
            }
            else if(resposta==true){
                try{
                    let result = await service.alterarSenha({
                        "senhaAntiga":senhaAntiga,
                        "novaSenha":novaSenha
                    })
                    if(result.status===200){
                        toast.success("Senha alterada")
                        setOpacity(0);
                        props.setModalIsOpen(!props.modalIsOpen);
                    }
                }
                catch(error){
                    throw error
                }
            }
        }
        catch(error){
            //console.log(error)
            if(error.response.status===401){
                toast.error("Sessao expirada, refaça o login")
                Cookie.remove('connect.sid')
                Cookie.remove('usuario.sid')
                Cookie.remove('perm.sid')
                Cookie.remove('areas.sid')
                Cookie.remove('paineis.sid')
                window.location.href="/"
            }
            else{
                toast.error(error.response.data.msg)
                limparCampos()
            }
        }
    },[resposta])

    const validarSenha = () =>{
        if(novaSenha!==confSenha){
            toast.error("A senhas não são iguais")
            return 0
        }
        if(senhaAntiga === null || senhaAntiga===""){
            toast.error("Informe a antiga senha")
            return 0
        }
        if(novaSenha === null || novaSenha===""){
            toast.error("Informe a nova senha")
            return 0
        }
        if(confSenha === null || confSenha===""){
            toast.error("Informe a confirmação da senha")
            return 0
        }
        setModalIsOpen(!modalIsOpen)
    }

    return (
        <div>
            <StyledModal
                isOpen={props.modalIsOpen}
                afterOpen={afterOpen}
                beforeClose={beforeClose}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
                opacity={opacity}
                background="#F2F2F2"
            >
                <Container>
                    <Titulo TextAlign="center" FontSize="18px" FontFamily="Montserrat" color="#303030" letterSpacing="0.15em" lineHeight="17px" 
                         marginBottom="8%" FontWeight="bold">
                        Alterar Senha
                    </Titulo>
                    <Input placeholder='SENHA ANTIGA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
                    PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px" bgcolor="#F2F2F2"
                    borderBottom="1px solid #C6C6C6" marginTop="20px" type="password" onKeyPress={props.keyEnter}
                    value={senhaAntiga} onChange={e=>{setSenhaAntiga(e.target.value)}}
                    />
                    <Input placeholder='NOVA SENHA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
                    PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px" bgcolor="#F2F2F2"
                    borderBottom="1px solid #C6C6C6" marginTop="20px" type="password" onKeyPress={props.keyEnter}
                    value={novaSenha} onChange={e=>{setNovaSenha(e.target.value)}}
                    />
                    <Input placeholder='CONFIRME SENHA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
                    PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px" bgcolor="#F2F2F2"
                    borderBottom="1px solid #C6C6C6" marginTop="20px" type="password" onKeyPress={props.keyEnter}
                    value={confSenha} onChange={e=>{setConfSenha(e.target.value)}}
                    />
                    <Botoes fontFamily="Montserrat" bgcolor="#F2F2F2" borderBottom="1px solid #C6C6C6" TextAlign="center" marginTop="50px" 
                    width="140px" FontSize="14px" fontWight="300" color="#656565" letterSpacing="0.27em" lineHeight="17px" Activecolor="#3997EE"
                    ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>validarSenha()}>
                        ALTERAR
                    </Botoes>
                </Container>
                <ModalConfirmacao setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                 frase="alterar ?" resposta={resposta} setResposta={setResposta}/>
            </StyledModal>
        </div>
    )
}

export default ModalTrocarSenha