import React  from 'react'
import { Container , Card , Grupo  } from './styles'
import { Botoes , Input , Titulo , Select , Option } from '../utilitarios'

const CRUDUsuario = (props)=>{

    const seInserir = () =>{
        if(props.id===""){
            return (
                <Card width="100%">
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CriarUsuario()} height="20px">
                                INSERIR
                        </Botoes>
                    </Grupo>
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CancelarOperacao()} height="20px">
                                CANCELAR
                        </Botoes>
                    </Grupo>
                </Card>
                )
        }
        else {
            return (
                    <Card width="100%">
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.AlterarUsuario()} height="20px">
                                    ALTERAR
                            </Botoes>
                        </Grupo>
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.DeletarUsuario()} height="20px">
                                    EXCLUIR
                            </Botoes>
                        </Grupo>
                    </Card>
                    )
        }
    }

    return(
        <Container width="100%" height="100%">
            <Container  width="100%" height="5%">
                <Titulo TextAlign="center" FontSize="19px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                    FontWeight="bold"  borderBottom="0.25px solid #C6C6C6">
                    {props.nomeOperacao}
                </Titulo>
            </Container>
            <Container width="100%" height="75%">
                <Card width="100%" marginBottom="5%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            NOME
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='NOME' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.nome} onChange={e=>{props.setNome(e.target.value)}}
                        />
                    </Grupo>                
                </Card>
                <Card width="100%" marginBottom="5%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            USUÁRIO
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='USUARIO' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.usuario} onChange={e=>{props.setUsuario(e.target.value)}}
                        />
                    </Grupo>    
                </Card>
                <Card width="100%" marginBottom="5%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            EMAIL
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='EMAIL' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.email} onChange={e=>{props.setEmail(e.target.value)}}
                        />
                    </Grupo>    
                </Card>
                {props.seInserir &&(
                    <Card width="100%" marginBottom="5%">
                        <Grupo>
                            <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                                FontWeight="bold">
                                SENHA
                            </Titulo>
                        </Grupo>
                        <Grupo>
                            <Input placeholder='SENHA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                            PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                            letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                            borderBottom="1px solid #C6C6C6" type="password" onKeyPress={props.keyEnter}
                            value={props.senhaUsuario} onChange={e=>{props.setSenhaUsuario(e.target.value)}}
                            />
                        </Grupo>    
                    </Card>
                )}
                <Card width="100%" marginBottom="5%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            NIVEL PERMISSAO
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Select fontFamily="Montserrat" fontWight="300" fontSize="16px" height="25px" bgcolor="WHITE" value={props.nivelPermissao}
                        name={props.nivelPermissao}
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" 
                        onChange={e=>props.setNivelPermissao(e.target.value)}>
                            <Option value="1"
                            fontFamily="Montserrat" fontWight="300" fontSize="16px" height="25px" bgcolor="WHITE" 
                            letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" >
                                ADM CLIENTE
                            </Option>
                            <Option value="2"
                            fontFamily="Montserrat" fontWight="300" fontSize="16px" height="25px" bgcolor="WHITE" 
                            letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" >
                                CLIENTE
                            </Option>
                        </Select>
                    </Grupo>                
                </Card>
                {!props.seInserir&&(
                    <Card width="100%">
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="180px" FontSize="14px" fontWight="500" color="#656565" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CadastrarPaineisUsuario()} height="20px">
                                    VINCULAR PAINEIS
                            </Botoes>
                        </Grupo>
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="180px" FontSize="14px" fontWight="500" color="#656565" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.ResetarSenhaUsuario()} height="20px">
                                    RESETAR SENHA
                            </Botoes>
                        </Grupo>
                    </Card>
                )}
            </Container>
            <Container  width="100%" height="5%">
                {seInserir()}
            </Container>
            
        </Container>
    )
}

export default CRUDUsuario