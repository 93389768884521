import React, { useState } from 'react'
import { Container , Card } from './styles'
import { Img , Botoes , Titulo } from '../utilitarios'
import logo from '../../imagens/PSAiLOGO3.png'
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PermIdentity  from '@material-ui/icons/PermIdentity';
import Button from '@material-ui/core/Button';

const HeaderHomeAdm = (props)=>{

    const [colorPermIdentity,setColorPermIdentity]=useState('black')
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setColorPermIdentity('#3997EE')
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setColorPermIdentity('black')
        setAnchorEl(null);
    };

    const handleClickAlterar = () =>{
        props.TrocarSenha()
        handleClose()
    }

    const handleClickDeslogar = () =>{
        props.deslogar()
        handleClose()
    }

    return (
        <Container>
            <Img src={logo} display="flex" JustifyContent="center" AlignItems="center" Hovercursor="pointer" width="22px"/>
            { /* <Tooltip>
                <Img src={logo} display="flex" JustifyContent="center" AlignItems="center" Hovercursor="pointer" width="22px"/>
            </Tooltip> */ 
            }
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <PermIdentity htmlColor={colorPermIdentity}/>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                      borderRadius:"17px"
                    }
                }}
            >
                <MenuItem style={{cursor:'default',backgroundColor: 'transparent'}}>
                    <Card flexDirection="column">
                        <Titulo FontSize="14px" FontFamily="Montserrat" color="#3997EE" lineHeight="17px">{props.nomeusuario}</Titulo>
                        <Titulo FontSize="12px" FontFamily="Montserrat" color="#656565" lineHeight="15px">{props.nivelpermissao}</Titulo>
                    </Card>
                </MenuItem>
                <MenuItem onClick={e=>handleClickAlterar()} style={{cursor:'default',backgroundColor: 'transparent'}}>
                    <Botoes fontFamily="Montserrat" bgcolor="#fff" borderBottom="1px solid #C6C6C6" TextAlign="left" 
                    width="130px" FontSize="12px" fontWight="300" color="#656565" letterSpacing="0.17em" lineHeight="17px" Activecolor="#3997EE"
                    ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.TrocarSenha()}>
                        ALTERAR SENHA
                    </Botoes>
                </MenuItem>
                <MenuItem onClick={e=>handleClickDeslogar()} style={{cursor:'default',backgroundColor: 'transparent'}}>
                    <Botoes fontFamily="Montserrat" bgcolor="#fff" borderBottom="1px solid #C6C6C6" TextAlign="left" 
                    width="45px" FontSize="12px" fontWight="300" color="#656565" letterSpacing="0.17em" lineHeight="17px" Activecolor="#3997EE"
                    ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.deslogar()}>
                        SAIR
                    </Botoes>
                </MenuItem>
            </Menu>
        </Container>
    )
}

export default HeaderHomeAdm