import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: #F2F2F2;
    display:flex;
    flex-direction: column;
`;

export const Content = styled.div`
    width: 94%;
    display: flex;
    margin-left: 3%;
    height: calc(100% - 40px - 50px);
    border: 0.5px solid #3997EE;
`

export const ContentGif = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`