import ApiService from "../../index";

class ConnectContaBI extends ApiService{
    constructor(){
        super('/api/v1/adm/contabi')
    }
    
    async consultarContaBI(query){
        return await this.get("/?"+query)
    }

    async criarContaBI(obj){
        return await this.post("/",obj)
    }

    async alterarContaBI(obj){
        return await this.put("/",obj)
    }

    async deleteContaBI(query){
        return await this.delete("/?"+query)
    }

    async listContaBI(query){
        return await this.get("/list?"+query)
    }

}

export default ConnectContaBI