import React, { useEffect } from 'react'
import { Auth_login, getNivelPermissaoCookie } from '../../utils'
import { Container } from './styles'
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import toast from 'react-hot-toast';
import './dashboard.css'

const Dashboard = (props)=>{

    useEffect(()=>{
        if(Auth_login()&&props.tokenEmbedded===null){
            const nivelpermissao = getNivelPermissaoCookie()
            if(nivelpermissao===0){
                window.location.href="/adm"
            }
            else if(nivelpermissao===1){
                window.location.href="/admcliente"
            }
            else if(nivelpermissao===2){
                window.location.href="/cliente"
            }
        }
    },[])

    

    const renderizar = () =>{
        if(props.tokenEmbedded!==null){
            return(
                <PowerBIEmbed
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual and qna
                  id: `${props.tokenEmbedded.reportId}`, 
                  embedUrl:
                  `${props.tokenEmbedded.embedUrl}`,
                  accessToken:
                    `${props.tokenEmbedded.accessToken}`,
                  tokenType: models.TokenType.Embed,
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false
                      }
                    }
                  }
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                        //console.log("Report loaded");
                      }
                    ],
                    [
                      "rendered",
                      function () {
                        //console.log("Report rendered");
                      }
                    ],
                    [
                      "error",
                      function (event) {
		if(event.detail.message!='Invoked filter serialization function with no filter.'&&
                           event.detail.message!='visualClickedFailed'){
                            //console.log(event.detail);
                            let idpainel = props.tokenEmbedded.idpainel
                            let idAreanegocio = props.tokenEmbedded.idAreanegocio
                            let idpaineisusuario = props.tokenEmbedded.idpaineisusuario
                            props.setTokenEmbedded(null)
                            props.setPainelRenderizado(
                              {
                                "idpainel":idpainel,
                                "idAreanegocio":idAreanegocio,
                                "idpaineisusuario":idpaineisusuario
                              }
                            )
                        }
                      }
                    ]
                  ])
                }
                cssClassName={"embed-container"}
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />
              )
        }
    }

    return(
        <Container>
            {renderizar()}
        </Container>
    )
}

export default Dashboard
