import ApiService from "../../index";

class ConnectUsuario extends ApiService{
    constructor(){
        super('/api/v1/adm/usuario')
    }
    
    async consultarUsuario(query){
        return await this.get("/?"+query)
    }

    async criarUsuario(obj){
        return await this.post("/",obj)
    }

    async alterarUsuario(obj){
        return await this.put("/",obj)
    }

    async deleteUsuario(query){
        return await this.delete("/?"+query)
    }

    async resetarSenhaUsuario(obj){
        return await this.put("/resetar_senha",obj)
    }
}

export default ConnectUsuario