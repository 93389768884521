import Cookie from "js-cookie"
import jwt_decode from "jwt-decode"

const key = `${process.env.REACT_APP_KEYPROFILE}`

export const getNivelPermissao = (token) =>{
    try{
        if(!token){
            return false
        }
        return jwt_decode(token,key,{ algorithm: 'RS256' }).nivelpermissao
    }
    catch(error){
        throw error
    }
}

export const Auth_login = () =>{
    const token = Cookie.get("perm.sid")
    if(token) return true
}

export const getNivelPermissaoCookie = ()=>{
    const token = Cookie.get("perm.sid")
    if(!token){
        return false
    }
    return getNivelPermissao(token)
}

export const Auth_login_Cliente = () =>{
    const token = Cookie.get("perm.sid")
    if(!token){
        return false
    }
    const nivelpermissao = getNivelPermissao(token)
    if(nivelpermissao===2){
        return true
    }
    return false
}


export const Auth_login_Adm_Cliente = () =>{
    const token = Cookie.get("perm.sid")
    if(!token){
        return false
    }
    const nivelpermissao = getNivelPermissao(token)
    if(nivelpermissao===1){
        return true
    }
    return false
}


export const Auth_login_Adm = () =>{
    const token = Cookie.get("perm.sid")
    if(!token){
        return false
    }
    const nivelpermissao = getNivelPermissao(token)
    if(nivelpermissao===0){
        return true
    }
    return false
}

export const getNomeCliente = () =>{
    const token = Cookie.get("usuario.sid")
    if(!token){
        return false
    }
    return jwt_decode(token,key,{ algorithm: 'RS256' }).nomeusuario
}

export const getNomeNivelPermissao = ()=>{
    const token = Cookie.get("perm.sid")
    if(!token){
        return false
    }
    let nivelPermissao = getNivelPermissao(token)
    if(nivelPermissao===0||nivelPermissao===1){
        return "Administrador"
    }
    else if(nivelPermissao===2){
        return "Cliente"
    }
}

export const getAreas = ()=>{
    const token = Cookie.get("areas.sid")
    if(!token){
        return false
    }
    return jwt_decode(token,key,{ algorithm: 'RS256' }).areas
}

export const getAreaPaineis = ()=>{
    let token = Cookie.get("paineis.sid")
    let paineis = jwt_decode(token,key,{ algorithm: 'RS256' }).paineis
    if(!token){
        return false
    }
    token = Cookie.get("areas.sid")
    if(!token){
        return false
    }
    let areas = jwt_decode(token,key,{ algorithm: 'RS256' }).areas
    let listareas = []
    let listpaineis = []
    for(let i in areas){
        listpaineis=[]
        for(let j in paineis){
            if(parseInt(areas[i].id) == parseInt(paineis[j].id)){
                listpaineis.push(paineis[j])
            }
        }
        listareas.push({
            "id":areas[i].id,
            "desc":areas[i].desc,
            "paineis":listpaineis
        })
    }
    return listareas
}