import React  from 'react'
import { Container , Card , Grupo  } from './styles'
import { Botoes , Input , Titulo , Select , Option } from '../utilitarios'

const CRUDCliente = (props)=>{

    const seInserir = () =>{
        if(props.id===""){
            return (
                <Card width="100%">
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CriarCliente()} height="20px">
                                INSERIR
                        </Botoes>
                    </Grupo>
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CancelarOperacao()} height="20px">
                                CANCELAR
                        </Botoes>
                    </Grupo>
                </Card>
                )
        }
        else {
            return (
                    <Card width="100%">
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.AlterarCliente()} height="20px">
                                    ALTERAR
                            </Botoes>
                        </Grupo>
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.DeletarCliente()} height="20px">
                                    EXCLUIR
                            </Botoes>
                        </Grupo>
                    </Card>
                    )
        }
    }

    return(
        <Container width="100%" height="100%">
            <Container  width="100%" height="8%">
                <Titulo TextAlign="center" FontSize="19px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                    FontWeight="bold"  borderBottom="0.25px solid #C6C6C6">
                    {props.nomeOperacao}
                </Titulo>
            </Container>
            <Container width="100%" height="75%">
                <Card width="100%" marginBottom="2.8%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            NOME
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='NOME' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.nome} onChange={e=>{props.setNome(e.target.value)}}
                        />
                    </Grupo>                
                </Card>
                <Card width="100%" marginBottom="2.8%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            ID PSA
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='ID PSA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.idPsa} onChange={e=>{props.setIdPsa(e.target.value)}}
                        />
                    </Grupo>    
                </Card>
                <Card width="100%" marginBottom="2.8%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            CNPJ
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='CNPJ' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.cnpj} onChange={e=>{props.setCnpj(e.target.value)}}
                        />
                    </Grupo>    
                </Card>
                <Card width="100%" marginBottom="2.8%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            QT LICENÇAS
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='QT LICENÇAS' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.qtlicencas} onChange={e=>{props.setQtLicencas(e.target.value)}}
                        />
                    </Grupo>    
                </Card>
                <Card width="100%" marginBottom="2.8%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            WORKSPACE ID
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='WORKSPACE ID' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.workspaceid} onChange={e=>{props.setWorkspaceId(e.target.value)}}
                        />
                    </Grupo>    
                </Card>
                <Card width="100%" marginBottom="2.8%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            CONTA BI
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Select fontFamily="Montserrat" fontWight="300" fontSize="16px" height="25px" bgcolor="WHITE" value={props.idContaBI}
                        name={props.nomeAreaNegocio}
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" 
                        onChange={e=>props.setIdContaBI(e.target.value)}>
                            {props.option.map((option) => (
                                <Option value={option.id}
                                fontFamily="Montserrat" fontWight="300" fontSize="16px" height="25px" bgcolor="WHITE" 
                                letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" >
                                    {option.nome}
                                </Option>
                            ))}
                        </Select>
                    </Grupo>                
                </Card>
                <Card width="100%" marginBottom="2.8%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            EMBEDDADO
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input type="checkbox" onKeyPress={props.keyEnter} 
                        checked={props.embbedado} onChange={e=>{props.setEmbbedado(!props.embbedado)}}
                        />
                    </Grupo>    
                </Card>
                {!props.seInserir&&(<Card width="100%">
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="180px" FontSize="14px" fontWight="500" color="#656565" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CadastrarUsuarios()} height="20px">
                                USUÁRIOS
                        </Botoes>
                    </Grupo>
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="180px" FontSize="14px" fontWight="500" color="#656565" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CadastrarPaineisCliente()} height="20px">
                                VINCULAR PAINEIS
                        </Botoes>
                    </Grupo>
                </Card>)}
            </Container>
            <Container  width="100%" height="10%">
                {seInserir()}
            </Container>
            
        </Container>
    )
}

export default CRUDCliente