import React, { useEffect, useState } from 'react'
import ConnectPaineisCliente from '../../apis/adm/vincularPaineisCliente'
import List from '../../componentes/lista'
import { Container , Card } from './styles'
import toast from 'react-hot-toast';
import CRUDPaineisCliente from '../../componentes/crud-paineis-cliente'
import { useParams } from 'react-router-dom'
import ConnectPainel from '../../apis/adm/painel';

const PaineisCliente = (props)=>{

    const { idcliente } = useParams()

    const service = new ConnectPaineisCliente()
    const servicePainel = new ConnectPainel()
    const [vincularPaineisCliente,setVincularPaineisCliente]=useState([])
    const [offset,setOffset]=useState(0)
    const [paginaAtual,setPaginaAtual]=useState(1)
    const [maximoPaginas,setMaximoPaginas]=useState(1)
    const [idVincularPaineisCliente,setIdVincularPaineisCliente]=useState(-1)
    const [abrirComponente,setAbrirComponente]=useState(false)
    const [id,setId]=useState("")
    const [optionAtual,setOptionAtual]=useState({})
    const [reportId,setReportId]=useState("")
    const [url,setUrl]=useState("")
    const [nomeOperacao,setNomeOperacao]=useState("")
    const [option,setOption]=useState([])
    const [seInserir,setSeInserir]=useState(true)

    useEffect(async()=>{
        if(props.paginaAtiva===null){
            window.location.href="/adm"
            return 0
        }
        await ConsultarPaineisCliente()
        await ConsultarPaineisAInserirCliente()
    },[])

    useEffect(async()=>{
        setVincularPaineisCliente([])
        await ConsultarPaineisCliente()
    },[offset])

    useEffect(()=>{
        if(paginaAtual>maximoPaginas){
            setPaginaAtual(1)
            setOffset(0)
        }
    },[maximoPaginas])


    useEffect(()=>{
        if(idVincularPaineisCliente===null){
            setId("")
            setNomeOperacao("INSERÇÂO")
            setReportId("")
            setUrl("")
            setAbrirComponente(true)
            setSeInserir(true)
            return 0
        }
        for(let i in vincularPaineisCliente){
            if(vincularPaineisCliente[i].id === idVincularPaineisCliente){
                setId(vincularPaineisCliente[i].id)
                setReportId(vincularPaineisCliente[i].reportid)
                setUrl(vincularPaineisCliente[i].url)
                setNomeOperacao("ALTERAÇÃO")
                setAbrirComponente(true)
                setSeInserir(false)
                return 0
            }
        }
    },[idVincularPaineisCliente])

    const validarInfos = () =>{
        if(reportId==""){
            toast.error("Informe o reportid")
            return false
        }
        if(url==""){
            toast.error("Informe a URL")
            return false
        }
        return true
    }

    const ConsultarPaineisCliente = async()=>{
        try{
            let url = `ativo=true&idpaineiscliente=null&idcliente=${idcliente}&idpainel=null&reportid=null&url=null&offset=`+offset
            let result = await service.consultarPaineisCliente(url)
            if(result.status===200){
                setVincularPaineisCliente([])
                let arrendondar = Math.ceil(result.data.count/6);
                if(arrendondar===0){
                    arrendondar=1
                }
                setMaximoPaginas(arrendondar)
                setVincularPaineisCliente(result.data.data)
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CriarPaineisCliente = async()=>{
        try{
            if(validarInfos()){
                let result = await service.criarPaineisCliente({
                    "idpainel":optionAtual.value,
                    "reportid":reportId,
                    "url":url,
                    "idcliente":idcliente
                })
                if(result.status===201){
                    toast.success("Inserido vinculação painel ao cliente")
                    await ConsultarPaineisCliente()
                    await ConsultarPaineisAInserirCliente()
                    CancelarOperacao()
                }
             }
         }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const AlterarPaineisCliente = async()=>{
        try{
            if(validarInfos()){
                let result = await service.alterarPaineisCliente({
                    "reportid":reportId,
                    "url":url,
                    "idpaineiscliente":id
                })
                if(result.status===200){
                    toast.success("Alterada vinculação painel ao cliente")
                    await ConsultarPaineisCliente()
                    await ConsultarPaineisAInserirCliente()
                    CancelarOperacao()
                }
             }
         }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const ConsultarPaineisAInserirCliente = async()=>{
        try{
            let url = `ativo=true&idpaineiscliente=null&idcliente=${idcliente}&idpainel=null&reportid=null&url=null`
            let result = await service.listPaineisCliente(url)
            let PainelClienteData = result.data.data

            url = `idpainel=null&nomepainel=null&idareanegocio=null&ativo=true`
            result = await servicePainel.listPainel(url)
            let PaineisData = result.data.data

            let list = []
            let painelNaoCad = true

            for(let i in PaineisData){
                for(let j in PainelClienteData){
                    if(parseInt(PaineisData[i].id) === parseInt(PainelClienteData[j].idpainel)){
                        painelNaoCad = false
                        break
                    }
                }
                if(painelNaoCad){
                    list.push({
                        "value":PaineisData[i].id,
                        "label":PaineisData[i].nome
                    })
                }
                painelNaoCad= true
            }
            setOptionAtual(list[0])
            setOption(list)
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const DeletarPaineisCliente = async()=>{
        try{
            if(validarInfos()){
                let url = "idpaineiscliente="+id
                let result = await service.deletarPaineisCliente(url)
                if(result.status===200){
                    toast.success("Deletado vincular painel a cliente")
                    await ConsultarPaineisCliente()
                    await ConsultarPaineisAInserirCliente()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CancelarOperacao = () =>{
        setAbrirComponente(false)
        setId("")
        setNomeOperacao("")
        setReportId("")
        setUrl("")
        setIdVincularPaineisCliente(-1)
        setSeInserir(true)
    }

    return(
        <Container>
            <Card>
                <List data={vincularPaineisCliente} isCrudUsuario={false} coluna1="ID" coluna2="NOME" nomePagina="CLIENTE > VINCULAR PAINEIS"
                  paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} 
                  paginaFinal={maximoPaginas} offset={offset} setOffset={setOffset}
                  setAbrirCrud={setIdVincularPaineisCliente}/>
            </Card>
            <Card>
                {abrirComponente &&(
                    <CRUDPaineisCliente id={id} setId={setId} 
                     nomeOperacao={nomeOperacao} seInserir={seInserir}
                     nome={reportId} setNome={setReportId} option={option}
                     url={url} setUrl={setUrl}
                     CancelarOperacao={CancelarOperacao} AlterarPaineisCliente={AlterarPaineisCliente}
                     setOptionAtual={setOptionAtual} optionAtual={optionAtual}
                     DeletarPaineisCliente={DeletarPaineisCliente} CriarPaineisCliente={CriarPaineisCliente}/>
                )}
            </Card>
        </Container>
    )
}

export default PaineisCliente