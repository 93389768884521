import React, { useEffect, useState } from 'react'
import ConnectPaineisUsuario from '../../apis/adm/vincularPaineisUsuario'
import List from '../../componentes/lista'
import { Container , Card } from './styles'
import toast from 'react-hot-toast';
import CRUDPaineisUsuario from '../../componentes/crud-paineis-usuario'
import { useParams } from 'react-router-dom'

const PaineisUsuario = (props)=>{

    const { idusuario } = useParams()

    const service = new ConnectPaineisUsuario()
    const [vincularPaineisUsuario,setVincularPaineisUsuario]=useState([])
    const [offset,setOffset]=useState(0)
    const [paginaAtual,setPaginaAtual]=useState(1)
    const [maximoPaginas,setMaximoPaginas]=useState(1)
    const [idVincularPaineisUsuario,setIdVincularPaineisUsuario]=useState(-1)
    const [abrirComponente,setAbrirComponente]=useState(false)
    const [id,setId]=useState("")
    const [optionAtual,setOptionAtual]=useState({})
    const [nomeOperacao,setNomeOperacao]=useState("")
    const [option,setOption]=useState([])
    const [seInserir,setSeInserir]=useState(true)

    useEffect(async()=>{
        if(props.paginaAtiva===null){
            window.location.href="/adm"
            return 0
        }
        await ConsultarPaineisUsuario()
        await ConsultarPaineisAInserirUsuario()
    },[])

    useEffect(async()=>{
        setVincularPaineisUsuario([])
        await ConsultarPaineisUsuario()
    },[offset])

    useEffect(()=>{
        if(paginaAtual>maximoPaginas){
            setPaginaAtual(1)
            setOffset(0)
        }
    },[maximoPaginas])


    useEffect(()=>{
        if(idVincularPaineisUsuario===null){
            if(option.length==0){
                toast.error("Todos os paineis já foram vinculados")
                CancelarOperacao()
                return 0
            }
            setId("")
            setNomeOperacao("INSERÇÂO")
            setAbrirComponente(true)
            setSeInserir(true)
            return 0
        }
        for(let i in vincularPaineisUsuario){
            if(vincularPaineisUsuario[i].id === idVincularPaineisUsuario){
                setId(vincularPaineisUsuario[i].id)
                setNomeOperacao("EXCLUSÂO")
                setAbrirComponente(true)
                setSeInserir(false)
                return 0
            }
        }
    },[idVincularPaineisUsuario])

    const validarInfos = () =>{
        return true
    }

    const ConsultarPaineisUsuario = async()=>{
        try{
            let url = `ativo=true&idpainel=null&idpaineisusuario=null&idareanegocio=null&idusuario=${idusuario}&limitarempresa=null&limitarempreend=null&offset=`+offset
            let result = await service.consultarPaineisUsuario(url)
            if(result.status===200){
                setVincularPaineisUsuario([])
                let arrendondar = Math.ceil(result.data.count/6);
                if(arrendondar===0){
                    arrendondar=1
                }
                setMaximoPaginas(arrendondar)
                setVincularPaineisUsuario(result.data.data)
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CriarPaineisUsuario = async()=>{
        try{
            if(validarInfos()){
                let result = await service.criarPaineisUsuario({
                    "idusuario":idusuario,
                    "idpainel":optionAtual.value,
                    "limitarempresa":false,
                    "limitarempreend":false
                })
                if(result.status===201){
                    toast.success("Inserido vinculação painel ao usuário")
                    await ConsultarPaineisUsuario()
                    await ConsultarPaineisAInserirUsuario()
                    CancelarOperacao()
                }
             }
         }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const ConsultarPaineisAInserirUsuario = async()=>{
        try{
            let url = `ativo=true&idpainel=null&idareanegocio=null&idpaineisusuario=null&idusuario=${idusuario}&limitarempresa=null&limitarempreend=null`
            let result = await service.listPaineisUsuario(url)
            let PainelUsuarioData = result.data.data

            url = `idusuario=${idusuario}`
            result = await service.listPaineisClienteUsuario(url)
            let PaineisData = result.data.data

            let list = []
            let painelNaoCad = true

            for(let i in PaineisData){
                for(let j in PainelUsuarioData){
                    if(parseInt(PaineisData[i].idpainel) === parseInt(PainelUsuarioData[j].idpainel)){
                        painelNaoCad = false
                        break
                    }
                }
                if(painelNaoCad){
                    list.push({
                        "value":PaineisData[i].idpainel,
                        "label":PaineisData[i].nome
                    })
                }
                painelNaoCad= true
            }
            setOptionAtual(list[0])
            setOption(list)
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const DeletarPaineisUsuario = async()=>{
        try{
            if(validarInfos()){
                let url = "idpaineisusuario="+id
                let result = await service.deletarPaineisUsuario(url)
                if(result.status===200){
                    toast.success("Deletado vincular painel a usuário")
                    await ConsultarPaineisUsuario()
                    await ConsultarPaineisAInserirUsuario()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CancelarOperacao = () =>{
        setAbrirComponente(false)
        setId("")
        setNomeOperacao("")
        setIdVincularPaineisUsuario(-1)
        setSeInserir(true)
    }

    return(
        <Container>
            <Card>
                <List data={vincularPaineisUsuario} isCrudUsuario={false} coluna1="ID" coluna2="NOME" nomePagina="CLIENTE > USUARIOS > VINCULAR PAINEIS"
                  paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} 
                  paginaFinal={maximoPaginas} offset={offset} setOffset={setOffset}
                  setAbrirCrud={setIdVincularPaineisUsuario}/>
            </Card>
            <Card>
                {abrirComponente &&(
                    <CRUDPaineisUsuario id={id} setId={setId} 
                     nomeOperacao={nomeOperacao} seInserir={seInserir}
                     CancelarOperacao={CancelarOperacao} 
                     setOptionAtual={setOptionAtual} optionAtual={optionAtual}
                     option={option}
                     DeletarPaineisUsuario={DeletarPaineisUsuario} CriarPaineisUsuario={CriarPaineisUsuario}/>
                )}
            </Card>
        </Container>
    )
}

export default PaineisUsuario