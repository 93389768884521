import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background: #F2F2F2;
`;


export const Card = styled.div`
    width: ${props=>props.width} ;
    height: ${props=>props.height} ;
    display:flex;
    flex-direction: column;
    justify-content: ${props=>props.justifyContent};
    align-items:center;
    background: #F2F2F2;
    border: ${props=>props.border};
`;

