import styled from "styled-components";

export const Container = styled.div`
    width: 94%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    margin-left: 3%;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: ${props=>props.flexDirection};
    margin: 1px;
`