import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react'
import { Botoes } from '../utilitarios'
import { Container, Card } from './styles'

const MenuPaineis = (props)=>{

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseAcessar = (idpaineisusuario,idpainel,idAreanegocio)=>{
        props.gerarTokenEmbedded(idpaineisusuario,idpainel,idAreanegocio)
        setAnchorEl(null);
    }

    const isDisable = (idpainel) => {
        if(props.painelAtivo===idpainel){
            return true
        }
        else{
            return false
        }
    }

    const colorPainel = (idpainel) =>{
        if(props.painelAtivo===idpainel){
            return "#3997EE"
        }
        else{
            return "#656565"
        }
    }

    const colorArea = (idarea) =>{
        if(props.areaAtiva===idarea){
            return "#3997EE"
        }
        else{
            return "#656565"
        }
    }

    const negritoPainel = (idpainel) =>{
        if(props.painelAtivo===idpainel){
            return "bold"
        }
        else{
            return "none"
        }
    }

    const negritoArea = (idarea) =>{
        if(props.areaAtiva===idarea){
            return "bold"
        }
        else{
            return "none"
        }
    }

    return (
        <Container>
            <Card>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <Botoes FontSize="14px" FontFamily="Montserrat" lineHeight="17px" bgcolor="white" 
                    color={colorArea(props.id)} fontWight={negritoArea(props.id)}
                     onClick="" >
                        {props.desc}
                    </Botoes>
                </Button>
                <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                      borderRadius:"12px"
                    }
                }}
                >   
                    {props.paineis.map(Objpainel=>{
                        return (
                            <MenuItem style={{cursor:'default',backgroundColor: 'transparent'}}
                            onClick={e=>handleCloseAcessar(Objpainel.idpaineisusuario,Objpainel.idpainel,props.id)} >
                                <Botoes FontSize="14px" FontFamily="Montserrat" lineHeight="17px" bgcolor="white" 
                                color={colorPainel(Objpainel.idpainel)} fontWight={negritoPainel(Objpainel.idpainel)}
                                onClick={e=>handleCloseAcessar(Objpainel.idpaineisusuario,Objpainel.idpainel,props.id)} 
                                disabled={isDisable(Objpainel.idpainel)}>
                                    {Objpainel.nomepainel}
                                </Botoes>
                            </MenuItem>
                        )
                    })}
                </Menu>
            </Card>
        </Container>
    )
}

export default MenuPaineis