import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    background: #F2F2F2;
    @media(max-width: 1100px) {
        display: flex;
        flex-direction: column;
    } 
`;

export const Card = styled.div`
    width: 50%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    border-radius: 3px;
    @media(max-width: 1100px) {
        padding-bottom:10%;
    } 
`;



