import React, { useEffect, useState } from 'react'
import List from '../../componentes/lista'
import { Container , Card } from './styles'
import toast from 'react-hot-toast';
import CRUDCliente from '../../componentes/crud-cliente';
import ConnectCliente from '../../apis/adm/cliente';
import ConnectContaBI from '../../apis/adm/contabi';


const ClienteAdm = (props)=>{

    const service = new ConnectCliente()
    const serviceContaBI = new ConnectContaBI()
    const [Paineis,setPaineis]=useState([])
    const [offset,setOffset]=useState(0)
    const [paginaAtual,setPaginaAtual]=useState(1)
    const [maximoPaginas,setMaximoPaginas]=useState(1)
    const [idCliente,setIdCliente]=useState(-1)
    const [abrirComponente,setAbrirComponente]=useState(false)
    const [id,setId]=useState("")
    const [nome,setNome]=useState("")
    const [idContaBI,setIdContaBI]=useState("")
    const [nomeContaBI,setNomeContaBI]=useState("")
    const [idPsa,setIdPsa]=useState("")
    const [cnpj,setCnpj]=useState("")
    const [qtlicencas,setQtLicencas]=useState("")
    const [workspaceid,setWorkspaceId]=useState("")
    const [embbedado,setEmbbedado]=useState(true)
    const [nomeOperacao,setNomeOperacao]=useState("")
    const [seInserir,setSeInserir]=useState(true)
    const [option,setOption]=useState([])

    useEffect(async()=>{
        if(props.paginaAtiva===null){
            window.location.href="/adm"
            return 0
        }
        await ConsultaContaBI()
        await ConsultarCliente()
    },[])

    useEffect(async()=>{
        setPaineis([])
        await ConsultarCliente()
    },[offset])

    useEffect(()=>{
        if(paginaAtual>maximoPaginas){
            setPaginaAtual(1)
            setOffset(0)
        }
    },[maximoPaginas])

    useEffect(()=>{
        if(option.length>0){
            gravarIdContaBI()
        }
    },[option])

    useEffect(()=>{
        if(idCliente===null){
            setId("")
            setNome("")
            setNomeOperacao("INSERÇÂO")
            setIdContaBI("")
            setNomeContaBI("")
            setIdPsa("")
            setCnpj("")
            setQtLicencas("")
            setWorkspaceId("")
            setEmbbedado(true)
            gravarIdContaBI()
            setAbrirComponente(true)
            setSeInserir(true)
            return 0
        }
        for(let i in Paineis){
            if(Paineis[i].id === idCliente){
                setId(Paineis[i].id)
                setNome(Paineis[i].nome)
                setNomeOperacao("ALTERAÇÃO")
                setIdContaBI(Paineis[i].idcontabi)
                setNomeContaBI(Paineis[i].nomecontabi)
                setIdPsa(Paineis[i].idpsa)
                setCnpj(Paineis[i].cnpj)
                setQtLicencas(Paineis[i].qtlicencas)
                setWorkspaceId(Paineis[i].workspaceid)
                setEmbbedado(Paineis[i].embbedado)
                setAbrirComponente(true)
                setSeInserir(false)
                return 0
            }
        }
    },[idCliente])

    const validarInfos = () =>{
        if(nome===""){
            toast.error("Informe o nome do cliente")
            return false
        }
        return true
    }

    const gravarIdContaBI = () =>{
        setIdContaBI(option[0].id)
        setNomeContaBI(option[0].nome)
    }

    const ConsultarCliente = async()=>{
        try{
            let url = "idcliente=null&idpsa=null&nomecliente=null&cnpj=null&qtlicencas=null&idcontabi=null&workspaceid=null&embbedado=null&ativo=true&offset="+offset
            let result = await service.consultarCliente(url)
            if(result.status===200){
                setPaineis([])
                let arrendondar = Math.ceil(result.data.count/6);
                if(arrendondar===0){
                    arrendondar=1
                }
                setMaximoPaginas(arrendondar)
                setPaineis(result.data.data)
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const ConsultaContaBI = async()=>{
        try{
            let url = "idcontabi=null&nomecontabi=null&usuariobi=null&senhabi=null&clientidbi=null&ativo=true&offset=0"
            let result = await serviceContaBI.listContaBI(url)
            if(result.status===200){
                setOption(result.data.data)
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CriarCliente = async()=>{
        try{
            if(validarInfos()){
                let result = await service.criarCliente({
                    "idpsa":idPsa,
                    "nomecliente":nome,
                    "cnpj":cnpj,
                    "qtlicencas":qtlicencas,
                    "idcontabi":idContaBI,
                    "workspaceid":workspaceid,
                    "embbedado":embbedado
                })
                if(result.status===201){
                    toast.success("Inserido novo cliente")
                    await ConsultarCliente()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const DeletarCliente = async()=>{
        try{
            if(validarInfos()){
                let url = "idcliente="+id
                let result = await service.deleteCliente(url)
                if(result.status===200){
                    toast.success("Deletado cliente")
                    await ConsultarCliente()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const AlterarCliente = async()=>{
        try{
            if(validarInfos()){
                let result = await service.alterarCliente({
                    "idpsa":idPsa,
                    "nomecliente":nome,
                    "cnpj":cnpj,
                    "qtlicencas":qtlicencas,
                    "idcontabi":idContaBI,
                    "workspaceid":workspaceid,
                    "embbedado":embbedado,
                    "idcliente":id
                })
                if(result.status===200){
                    toast.success("Alterado cliente")
                    await ConsultarCliente()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CancelarOperacao = () =>{
        setAbrirComponente(false)
        setId("")
        setNome("")
        setNomeOperacao("")
        setIdContaBI("")
        setNomeContaBI("")
        setIdPsa("")
        setCnpj("")
        setQtLicencas("")
        setWorkspaceId("")
        setEmbbedado(true)
        setSeInserir(true)
        setIdCliente(-1)
    }

    const CadastrarUsuarios = () =>{
        let link = "/adm/usuario/"+id
        props.history.push(link)
    }

    const CadastrarPaineisCliente = () =>{
        let link = "/adm/paineiscliente/"+id
        props.history.push(link)
    }


    return(
        <Container>
            <Card>
                <List data={Paineis} isCrudUsuario={false} coluna1="ID" coluna2="NOME" nomePagina="CLIENTE"
                  paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} 
                  paginaFinal={maximoPaginas} offset={offset} setOffset={setOffset}
                  setAbrirCrud={setIdCliente} />
            </Card>
            <Card>
                {abrirComponente &&(
                    <CRUDCliente id={id} setId={setId} nomeOperacao={nomeOperacao}
                     nome={nome} setNome={setNome} option={option} 
                     idPsa={idPsa} setIdPsa={setIdPsa}
                     cnpj={cnpj} setCnpj={setCnpj}
                     qtlicencas={qtlicencas} setQtLicencas={setQtLicencas}
                     workspaceid={workspaceid} setWorkspaceId={setWorkspaceId}
                     embbedado={embbedado} setEmbbedado={setEmbbedado}
                     nomeContaBI={nomeContaBI} setNomeContaBI={setNomeContaBI}
                     idContaBI={idContaBI} setIdContaBI={setIdContaBI}
                     CancelarOperacao={CancelarOperacao} AlterarCliente={AlterarCliente}
                     DeletarCliente={DeletarCliente} CriarCliente={CriarCliente}
                     seInserir={seInserir}
                     CadastrarUsuarios={CadastrarUsuarios} CadastrarPaineisCliente={CadastrarPaineisCliente}
                     />
                )}
            </Card>
        </Container>
    )
}

export default ClienteAdm