import ApiService from "../index";

class ConnectLogin extends ApiService{
    constructor(){
        super('/api/v1')
    }
    
    async login(cred){
        return await this.post("/login",cred)
    }

    async verificarVivo(){
        return await this.get("/cliente/me")
    }

    async deslogar(){
        return await this.get("/cliente/logout")
    }

    async alterarSenha(cred){
        return await this.put("/cliente/alterar-senha",cred)
    }

    async esqueciSenha(obj){
        return await this.post("/esqueci-senha",obj)
    }

    async validarToken(obj){
        return await this.post("/validar-token-senha",obj)
    }

    async redefinirSenha(obj){
        return await this.post("/redefinir-senha",obj)
    }

}

export default ConnectLogin