import React from 'react'
import { Switch,BrowserRouter,Route } from 'react-router-dom'
import HomeAdm from '../paginas/homeAdm'
import HomeAdmCliente from '../paginas/homeAdmCliente'
import HomeCliente from '../paginas/homeCliente'
import Login from '../paginas/login'
import RedefinirSenha from '../paginas/redefinirSenha'
import PrivateRouteCliente from './private-cliente-routes'
import PrivateRouteAdmCliente from './private-adm-cliente-routes'
import PrivateRouteAdm from './private-adm-routes'
import Redirecionador from '../paginas/redirecionador'
import PublicRoute from './public-routes'

const RoutesProfile = () =>{
    return(
        <BrowserRouter>
            <Switch>
                <PublicRoute      exact path="/"                       component={Login}/>
                <PublicRoute            path="/redefinir-senha/:token" component={RedefinirSenha}/>
                <Route                  path="/redirect"               component={Redirecionador}/>
                <PrivateRouteAdm        path="/adm"                    component={HomeAdm}/>
                <PrivateRouteAdmCliente path="/admcliente"             component={HomeAdmCliente}/>
                <PrivateRouteCliente    path="/cliente"                component={HomeCliente}/>
            </Switch>
        </BrowserRouter>
    )
}

export default RoutesProfile