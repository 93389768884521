import ApiService from "../../index";

class ConnectCliente extends ApiService{
    constructor(){
        super('/api/v1/adm/cliente')
    }
    
    async consultarCliente(query){
        return await this.get("/?"+query)
    }

    async criarCliente(obj){
        return await this.post("/",obj)
    }

    async alterarCliente(obj){
        return await this.put("/",obj)
    }

    async deleteCliente(query){
        return await this.delete("/?"+query)
    }

}

export default ConnectCliente