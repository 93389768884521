import { useState , useEffect } from "react";
import { StyledModal , Container } from './styles'
import {Botoes,Input, Titulo} from '../utilitarios'
import ConnectLogin from "../../apis/login";
import toast from 'react-hot-toast';

const ModalEsqueciSenha = (props)=>{

    const [opacity, setOpacity] = useState(0);
    const [email,setEmail]=useState("")
    const [usuario,setUsuario]=useState("")

    const service = new ConnectLogin()

    useEffect(()=>{
        limparCampos()
    },[])

    const limparCampos = ()=>{
        setEmail("")
        setUsuario("")
    }

    function toggleModal(e) {
        setOpacity(0);
        limparCampos()
        props.setModalIsOpen(!props.modalIsOpen);
      }
    
    function afterOpen() {
      setTimeout(() => {
        limparCampos()
        setOpacity(1);
      }, 50);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        limparCampos()
        setTimeout(resolve, 50);
      });
    }

    const validarCampos = () =>{
        if(email === null || email===""){
            toast.error("Informe o e-mail")
            return false
        }
        if(usuario === null || usuario===""){
            toast.error("Informe o usuário")
            return false
        }
        return true
    }

    const EsqueciSenha = async()=>{
        if(!validarCampos()){
            return 0
        }
        try{
            let result = await service.esqueciSenha({
                "usuario":usuario,
                "email":email
            })
            if(result.status===200){
                toast.success("Se o e-mail e usuário estiverem corretos, será enviado um e-mail para redefinição da senha")
                setOpacity(0);
                props.setModalIsOpen(!props.modalIsOpen);
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }


    return(
        <div>
            <StyledModal
                isOpen={props.modalIsOpen}
                afterOpen={afterOpen}
                beforeClose={beforeClose}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
                opacity={opacity}
                background="#F2F2F2"
            >
                <Container>
                <Titulo TextAlign="center" FontSize="18px" FontFamily="Montserrat" color="#303030" letterSpacing="0.15em" lineHeight="17px" 
                         marginBottom="8%" FontWeight="bold">
                        Esqueci a Senha
                    </Titulo>
                    <Input placeholder='EMAIL CADASTRADO' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
                    PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px" bgcolor="#F2F2F2"
                    borderBottom="1px solid #C6C6C6" marginTop="20px" type="text" onKeyPress={props.keyEnter}
                    value={email} onChange={e=>{setEmail(e.target.value)}}
                    />
                    <Input placeholder='USUÁRIO' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
                    PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px" bgcolor="#F2F2F2"
                    borderBottom="1px solid #C6C6C6" marginTop="20px" type="text" onKeyPress={props.keyEnter}
                    value={usuario} onChange={e=>{setUsuario(e.target.value)}}
                    />
                    <Botoes fontFamily="Montserrat" bgcolor="#F2F2F2" borderBottom="1px solid #C6C6C6" TextAlign="center" marginTop="50px" 
                    width="140px" FontSize="14px" fontWight="300" color="#656565" letterSpacing="0.27em" lineHeight="17px" Activecolor="#3997EE"
                    ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>EsqueciSenha()}>
                        PRÓXIMO
                    </Botoes>
                </Container>
            </StyledModal>
        </div>
    )
}

export default ModalEsqueciSenha