import { useState } from "react";
import { StyledModal , Container, Card } from './styles'
import {Botoes, Titulo} from '../utilitarios'

const ModalConfirmacao = (props)=>{

    const [opacity, setOpacity] = useState(0);
    function toggleModal(e) {
        setOpacity(0);
        props.setModalIsOpen(!props.modalIsOpen);
      }
    
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 50);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 50);
      });
    }

    const clicou = (escolha) =>{
        props.setResposta(escolha)
        setOpacity(0);
        props.setModalIsOpen(!props.modalIsOpen);
    }

    return (
        <div>
            <StyledModal
                isOpen={props.modalIsOpen}
                afterOpen={afterOpen}
                beforeClose={beforeClose}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
                opacity={opacity}
                background="#F2F2F2"
            >
                <Container>
                    <Titulo TextAlign="center" FontSize="18px" FontFamily="Montserrat" color="#303030" letterSpacing="0.15em" lineHeight="17px" 
                         marginBottom="1%" FontWeight="bold" marginTop="5%" >
                        Deseja {props.frase}
                    </Titulo>
                    <Card>
                        <Botoes fontFamily="Montserrat" bgcolor="#F2F2F2" borderBottom="1px solid #C6C6C6" TextAlign="center" marginRight="8%"
                            width="100px" FontSize="12px" fontWight="100" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>clicou(true)}>
                                CONFIRMAR
                        </Botoes>
                        <Botoes fontFamily="Montserrat" bgcolor="#F2F2F2" borderBottom="1px solid #C6C6C6" TextAlign="center"  marginLeft="8%"
                            width="100px" FontSize="12px" fontWight="100" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>clicou(false)}>
                                CANCELAR
                        </Botoes>
                    </Card>
                </Container>
            </StyledModal>
        </div>
    )
}

export default ModalConfirmacao