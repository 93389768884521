import React, { useEffect, useState } from 'react'
import ConnectUsuario from '../../apis/adm/usuario'
import List from '../../componentes/lista'
import { Container , Card } from './styles'
import toast from 'react-hot-toast';
import CRUDUsuario from '../../componentes/crud-usuario';
import { useParams } from 'react-router-dom'


const UsuarioAdm = (props)=>{

    const { idcliente } = useParams()

    const service = new ConnectUsuario()
    const [usuarios,setUsuarios]=useState([])
    const [offset,setOffset]=useState(0)
    const [paginaAtual,setPaginaAtual]=useState(1)
    const [maximoPaginas,setMaximoPaginas]=useState(1)
    const [idUsuario,setIdUsuario]=useState(-1)
    const [abrirComponente,setAbrirComponente]=useState(false)
    const [id,setId]=useState("")
    const [nome,setNome]=useState("")
    const [usuario,setUsuario]=useState("")
    const [senhaUsuario,setSenhaUsuario]=useState("")
    const [email,setEmail]=useState("")
    const [nivelPermissao,setNivelPermissao]=useState("")
    const [nomeOperacao,setNomeOperacao]=useState("")
    const [seInserir,setSeInserir]=useState(true)

    useEffect(async()=>{
        if(props.paginaAtiva===null){
            window.location.href="/adm"
            return 0
        }
        await ConsultarUsuario()
    },[])

    useEffect(async()=>{
        setUsuarios([])
        await ConsultarUsuario()
    },[offset])

    useEffect(()=>{
        if(paginaAtual>maximoPaginas){
            setPaginaAtual(1)
            setOffset(0)
        }
    },[maximoPaginas])

    useEffect(()=>{
        if(idUsuario===null){
            setId("")
            setNome("")
            setNomeOperacao("INSERÇÂO")
            setUsuario("")
            setSenhaUsuario("")
            setEmail("")
            setNivelPermissao("")
            setSeInserir(true)
            setAbrirComponente(true)
            return 0
        }
        for(let i in usuarios){
            if(usuarios[i].id === idUsuario){
                setId(usuarios[i].id)
                setNome(usuarios[i].nome)
                setUsuario(usuarios[i].usuario)
                setEmail(usuarios[i].email)
                setNivelPermissao(usuarios[i].nivelpermissao)
                setNomeOperacao("ALTERAÇÃO")
                setSeInserir(false)
                setAbrirComponente(true)
                return 0
            }
        }
    },[idUsuario])

    const validarInfos = (flag) =>{
        if(nome===""){
            toast.error("Informe o nome do usuário")
            return false
        }
        if(usuario===""){
            toast.error("Informe o usuário")
            return false
        }
        if(senhaUsuario===""&&flag===1){
            toast.error("Informe a senha do usuário")
            return false
        }
        if(email===""){
            toast.error("Informe o email")
            return false
        }
        return true
    }

    const ConsultarUsuario = async()=>{
        try{
            let url = `ativo=true&idusuario=null&nomeusuario=null&usuario=null&idcliente=${idcliente}&email=null&nivelpermissao=null&offset=`+offset
            let result = await service.consultarUsuario(url)
            if(result.status===200){
                setUsuarios([])
                let arrendondar = Math.ceil(result.data.count/6);
                if(arrendondar===0){
                    arrendondar=1
                }
                setMaximoPaginas(arrendondar)
                setUsuarios(result.data.data)
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CriarUsuario = async()=>{
        try{
            if(validarInfos(1)){
                let result = await service.criarUsuario({
                    "nomeusuario":nome,
                    "usuario":usuario,
                    "senhaUsuario":senhaUsuario,
                    "idcliente":idcliente,
                    "email":email,
                    "nivelpermissao":nivelPermissao
                })
                if(result.status===201){
                    toast.success("Inserido novo usuário")
                    await ConsultarUsuario()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const DeletarUsuario = async()=>{
        try{
            let url = "idusuario="+id
            let result = await service.deleteUsuario(url)
            if(result.status===200){
                toast.success("Deletado usuário")
                await ConsultarUsuario()
                CancelarOperacao()
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const AlterarUsuario = async()=>{
        try{
            if(validarInfos(0)){
                let result = await service.alterarUsuario({
                    "nomeusuario":nome,
                    "idcliente":idcliente,
                    "email":email,
                    "nivelpermissao":nivelPermissao,
	                "usuario":usuario,
                    "idusuario":id
                })
                if(result.status===200){
                    toast.success("Alterado usuário")
                    await ConsultarUsuario()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CancelarOperacao = () =>{
        setAbrirComponente(false)
        setId("")
        setNome("")
        setNomeOperacao("")
        setIdUsuario(-1)
        setUsuario("")
        setSenhaUsuario("")
        setEmail("")
        setNivelPermissao("")
        setSeInserir(true)
    }

    const CadastrarPaineisUsuario = () =>{
        let link = "/adm/paineisusuario/"+id
        props.history.push(link)
    }

    const ResetarSenhaUsuario = async() =>{
        try{
            const obj ={
                "idusuario":id
            }
            const result = await service.resetarSenhaUsuario(obj)
            if(result.status===200){
                toast.success("Senha resetada usuário")
                await ConsultarUsuario()
                CancelarOperacao()
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }


    return(
        <Container>
            <Card>
                <List data={usuarios} isCrudUsuario={true} coluna1="USUARIO" coluna2="NOME" nomePagina="CLIENTE > USUARIOS"
                  paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} 
                  paginaFinal={maximoPaginas} offset={offset} setOffset={setOffset}
                  setAbrirCrud={setIdUsuario} />
            </Card>
            <Card>
                {abrirComponente &&(
                    <CRUDUsuario id={id} setId={setId} nomeOperacao={nomeOperacao}
                     nome={nome} setNome={setNome} 
                     usuario={usuario} setUsuario={setUsuario}
                     email={email} setEmail={setEmail}
                     senhaUsuario={senhaUsuario} setSenhaUsuario={setSenhaUsuario}
                     nivelPermissao={nivelPermissao} setNivelPermissao={setNivelPermissao}
                     CancelarOperacao={CancelarOperacao} AlterarUsuario={AlterarUsuario}
                     seInserir={seInserir} CadastrarPaineisUsuario={CadastrarPaineisUsuario}
                     ResetarSenhaUsuario={ResetarSenhaUsuario}
                     DeletarUsuario={DeletarUsuario} CriarUsuario={CriarUsuario}/>
                )}
            </Card>
        </Container>
    )
}

export default UsuarioAdm