import React from 'react'
import { Container , Card , Grupo  } from './styles'
import { Botoes , Input , Titulo } from '../utilitarios'
import Select from 'react-select'

const CRUDPaineisCliente = (props)=>{

    const seInserir = () =>{
        if(props.id===""){
            return (
                <Card width="100%">
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CriarPaineisCliente()} height="20px">
                                INSERIR
                        </Botoes>
                    </Grupo>
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CancelarOperacao()} height="20px">
                                CANCELAR
                        </Botoes>
                    </Grupo>
                </Card>
                )
        }
        else {
            return (
                    <Card width="100%">
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.AlterarPaineisCliente()} height="20px">
                                    ALTERAR
                            </Botoes>
                        </Grupo>
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.DeletarPaineisCliente()} height="20px">
                                    EXCLUIR
                            </Botoes>
                        </Grupo>
                    </Card>
                    )
        }
    }

    const customStyles = {
        control:(css) => ({
            ...css,
            width: 250,
            fontSize:16,
            textAlign: "center"
        }),
        menu: (css) => ({
            ...css,
            width: 250,
            TextAlign:'center',
            fontSize:16,
            textAlign: "center"
        })
      }
      

    return(
        <Container width="100%" height="100%">
            <Container  width="100%" height="5%">
                <Titulo TextAlign="center" FontSize="19px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                    FontWeight="bold"  borderBottom="0.25px solid #C6C6C6">
                    {props.nomeOperacao}
                </Titulo>
            </Container>
            <Container width="100%" height="75%">
                <Card width="100%" marginBottom="10%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            REPORT ID
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='REPORT ID' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px)" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.nome} onChange={e=>{props.setNome(e.target.value)}}
                        />
                    </Grupo>                
                </Card>
                <Card width="100%" marginBottom="10%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            URL
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='URL' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px)" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.url} onChange={e=>{props.setUrl(e.target.value)}}
                        />
                    </Grupo>                
                </Card>
                {props.seInserir &&(
                    <Card width="100%" marginBottom="5%">
                        <Grupo>
                            <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                                FontWeight="bold">
                                PAINEL
                            </Titulo>
                        </Grupo>
                        <Grupo>
                            <Card width="calc(70% + 50px)">
                                <Select styles={customStyles} value={props.optionAtual} options={props.option} onChange={e=>props.setOptionAtual(e)}/>
                            </Card>
                        </Grupo>                
                    </Card>
                )}
            </Container>
            <Container  width="100%" height="50%">
                {seInserir()}
            </Container>
            
        </Container>
    )
}

export default CRUDPaineisCliente