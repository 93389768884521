import ApiService from "../../index";

class ConnectPainel extends ApiService{
    constructor(){
        super('/api/v1/adm/painel')
    }
    
    async consultarPainel(query){
        return await this.get("/?"+query)
    }

    async listPainel(query){
        return await this.get("/list?"+query)
    }

    async criarPainel(obj){
        return await this.post("/",obj)
    }

    async alterarPainel(obj){
        return await this.put("/",obj)
    }

    async deletePainel(query){
        return await this.delete("/?"+query)
    }

}

export default ConnectPainel