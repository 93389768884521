import styled from "styled-components";


export const Container = styled.div`
    width: 94%;
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    margin-left: 3%;
    flex-wrap: wrap;
    
`;

export const Card = styled.div`
    display: flex;
    margin: 1px;
    margin: 0.3% 0.3%;
    padding: 0.3% 0.3%;
`