import ApiService from "../../index";

class ConnectPaineisUsuario extends ApiService{
    constructor(){
        super('/api/v1/adm/paineisUsuario')
    }
    
    async consultarPaineisUsuario(query){
        return await this.get("/?"+query)
    }

    async listPaineisUsuario(query){
        return await this.get("/list?"+query)
    }

    async listPaineisClienteUsuario(query){
        return await this.get("/paineisclienteusuario?"+query)
    }

    async criarPaineisUsuario(obj){
        return await this.post("/",obj)
    }

    async deletarPaineisUsuario(query){
        return await this.delete("/?"+query)
    }

    async deletarTodasPaineisUsuario(query){
        return await this.delete("/delete-vinculacao-usuario?"+query)
    }

}

export default ConnectPaineisUsuario