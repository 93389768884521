import Modal from "styled-react-modal";
import styled from "styled-components";

export const StyledModal = Modal.styled`
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  background: ${props => props.background};
  opacity: ${props => props.opacity};
  transition : all 0.5s ease-in-out;
  border-radius:10px;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
`;

export const Card = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
`