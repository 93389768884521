import styled from "styled-components";


export const Container = styled.div`
    width: 94%;
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    margin-left: 3%;
    flex-wrap: wrap;
    
`;

export const Card = styled.div`
    display: flex;
    flex-direction: ${props=>props.flexDirection};
    margin: 1px;
    margin: 0.5% 0.5%;
    padding: 0.5% 0.5%;
    background: #FFFFFF;
    border-width: 0.5px 0px;
    border-style: solid;
    border-color: #FFFFFF;
    height: 30px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
`