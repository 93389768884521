import React from 'react'
import { Botoes } from '../utilitarios'
import { Container, Card } from './styles'

const FooterHomeAdm = (props)=>{

    const color = (opcaoComponente) =>{
        if(props.paginaAtiva===opcaoComponente){
            return "#3997EE"
        }
        else{
            return "#656565"
        }
    }

    const isDisable = (opcaoComponente) =>{
        if(props.paginaAtiva===opcaoComponente&&props.deveDesabilitar){
            return true
        }
        else{
            return false
        }
    }

    return(
        <Container>
            <Card>
                <Botoes FontSize="14px" FontFamily="Montserrat" color={color(1)} lineHeight="17px" bgcolor="white" FontWeight="bold"
                Activecolor="#3997EE" onClick={e=>props.setPaginaAtiva(1)} disabled={isDisable(1)}>
                    Área de Negocio
                </Botoes>
            </Card>
            <Card>
                <Botoes FontSize="14px" FontFamily="Montserrat" color={color(2)} lineHeight="17px" bgcolor="white" FontWeight="bold"
                Activecolor="#3997EE" onClick={e=>props.setPaginaAtiva(2)}  disabled={isDisable(2)}>
                    Conta BI
                </Botoes>
            </Card>
            <Card>
                <Botoes FontSize="14px" FontFamily="Montserrat" color={color(3)} lineHeight="17px" bgcolor="white" FontWeight="bold"
                Activecolor="#3997EE" onClick={e=>props.setPaginaAtiva(3)}  disabled={isDisable(3)}>
                    Dashboard
                </Botoes>
            </Card>
            <Card>
                <Botoes FontSize="14px" FontFamily="Montserrat" color={color(4)} lineHeight="17px" bgcolor="white" FontWeight="bold"
                Activecolor="#3997EE" onClick={e=>props.setPaginaAtiva(4)}  disabled={isDisable(4)}>
                    Cliente
                </Botoes>
            </Card>
        </Container>
    )
}

export default FooterHomeAdm
