import React, { useState,useEffect } from 'react'
import ConnectLogin from '../../apis/login'
import Cookie from 'js-cookie'
import toast from 'react-hot-toast';
import logo from '../../imagens/PSAiLOGO2.png'
import {Container,Card} from './styles'
import { Img } from '../../componentes/utilitarios'
import GroupLogin from '../../componentes/group-login'
import { getNivelPermissao , getNivelPermissaoCookie , Auth_login } from '../../utils'
import ModalEsqueciSenha from '../../componentes/modal-esquecisenha';

const Login = (props)=>{

    useEffect(()=>{
        if(Auth_login()){
            const nivelpermissao = getNivelPermissaoCookie()
            if(nivelpermissao===0){
                window.location.href="/adm"
            }
            else if(nivelpermissao===1){
                window.location.href="/admcliente"
            }
            else if(nivelpermissao===2){
                window.location.href="/cliente"
            }
        }
    },[])

    const [username,setUsername]=useState('')
    const [password,setPassword]=useState('')
    const [loading,setLoading]=useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const service = new ConnectLogin()

    const keyEnter = (event)=>{
        if(event.charCode ===13){
            Logar()
        }
    }

    const Logar = async() =>{
        try{
            setLoading(true)
            if(username===''){
                toast.error("Digite o usuário")
                setLoading(false)
            }
            else if(password===''){
                toast.error("Digite a senha")
                setLoading(false)
            }
            else{
                let obj={
                    "username":username,
                    "password":password
                }
                let login = await service.login(obj)
                if(login.status===200){
                    Cookie.set("usuario.sid",login.data.tokenusuario.token)
                    Cookie.set("perm.sid",login.data.tokennivelpermissao.token)
                    Cookie.set("areas.sid",login.data.tokenareas.token)
                    Cookie.set("paineis.sid",login.data.tokenpaineis.token)
                    toast.success("Login realizado")
                    let nivelpermissao = getNivelPermissao(login.data.tokennivelpermissao.token)
                    if(nivelpermissao===0){
                        props.history.push('/adm')
                    }
                    else if(nivelpermissao===1){
                        props.history.push('/admcliente')
                    }
                    else if(nivelpermissao===2){
                        props.history.push('/cliente')
                    }
                }
                setLoading(false)
            }
        }
        catch(error){
            setLoading(false)
            toast.error(error.response.data.erro)
        }
    }

    const abrirModal = () =>{
        setModalIsOpen(!modalIsOpen)
    }

    return(
        <Container>
            <Card>
                <Img src={logo} display="flex" JustifyContent="center" AlignItems="center" width="calc(350px + 10%)"/>
            </Card>
            <Card JustifyContent="center" AlignItems="center">
                <GroupLogin username={username} setUsername={setUsername}
                            password={password} setPassword={setPassword}
                            loading={loading}   setLoading={setLoading}
                            abrirModal={abrirModal}
                            keyEnter={keyEnter}
                            Logar={Logar}/>
            </Card>
            <ModalEsqueciSenha setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}/>
        </Container>
    )
}

export default Login