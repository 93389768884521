import React from 'react'
import { Container , Card , Grupo  } from './styles'
import { Botoes , Input , Titulo } from '../utilitarios'
import Select from 'react-select'

const CRUDPaineisUsuario = (props)=>{

    const seInserir = () =>{
        if(props.id===""){
            return (
                <Card width="100%">
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CriarPaineisUsuario()} height="20px">
                                INSERIR
                        </Botoes>
                    </Grupo>
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CancelarOperacao()} height="20px">
                                CANCELAR
                        </Botoes>
                    </Grupo>
                </Card>
                )
        }
        else {
            return (
                    <Card width="100%">
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.DeletarPaineisUsuario()} height="20px">
                                    EXCLUIR
                            </Botoes>
                        </Grupo>
                    </Card>
                    )
        }
    }

    const customStyles = {
        control:(css) => ({
            ...css,
            width: 250,
            fontSize:16,
            textAlign: "center"
        }),
        menu: (css) => ({
            ...css,
            width: 250,
            TextAlign:'center',
            fontSize:16,
            textAlign: "center"
        })
      }
      
    
    return(
        <Container width="100%" height="100%">
            <Container  width="100%" height="5%">
                <Titulo TextAlign="center" FontSize="19px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                    FontWeight="bold"  borderBottom="0.25px solid #C6C6C6">
                    {props.nomeOperacao}
                </Titulo>
            </Container>
            {props.seInserir &&(
                <Container  width="100%" height="75%">
                    <Container width="100%" height="75%">
                        <Card width="100%" marginBottom="5%">
                            <Grupo>
                                <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                                    FontWeight="bold">
                                    PAINEL
                                </Titulo>
                            </Grupo>
                            <Grupo>
                                <Card width="calc(70% + 50px)">
                                    <Select styles={customStyles} value={props.optionAtual} options={props.option} onChange={e=>props.setOptionAtual(e)}/>
                                </Card>
                            </Grupo>                
                        </Card>
                    </Container>
                    <Container  width="100%" height="50%">
                        {seInserir()}
                    </Container>
                </Container>
                
            )}
            {!props.seInserir &&(
                <Container  width="100%" height="85%">
                    {seInserir()}
                </Container>)
            }
            
        </Container>
    )
}

export default CRUDPaineisUsuario