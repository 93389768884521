import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ConnectLogin from '../../apis/login'
import { getNivelPermissaoCookie , Auth_login } from '../../utils'
import { Container , Card } from './styles'
import toast from 'react-hot-toast';
import { Botoes, Img, Input } from '../../componentes/utilitarios'
import logo from '../../imagens/PSAiLOGO2.png'

const RedefinirSenha = (props)=>{

    const { token } = useParams()
    const service = new ConnectLogin()
    const [valido,setValido]=useState(null)
    const [senhaUsuario,setSenhaUsuario]=useState("")
    const [confSenha,setConfSenha]=useState("")

    useEffect(async()=>{
        if(Auth_login()){
            const nivelpermissao = getNivelPermissaoCookie()
            if(nivelpermissao===0){
                window.location.href="/adm"
            }
            else if(nivelpermissao===1){
                window.location.href="/admcliente"
            }
            else if(nivelpermissao===2){
                window.location.href="/cliente"
            }
        }
        try{
            let validador = await service.validarToken({
                "token":token
            })
            if(validador.data.status){
                setValido(true)
            }
            else{
                toast.error("Sessão invalida")
                window.location.href="/"
            }
        }
        catch(error){
            //mostrarMensagem('Erro','error',error)
            //console.log(error.response.data.erro)
            toast.error(error.response.data.erro)
        }
    },[])

    const validarDadosSenha = () =>{
        if(senhaUsuario===""){
            toast.error("Informe a nova senha")
            return false
        }
        if(confSenha===""){
            toast.error("Informe a confirmação da senha")
            return false
        }
        if(senhaUsuario!==confSenha){
            toast.error("A senhas não são iguais")
            return false
        }
        return true
    }

    const RedefinirASenha = async()=>{
        try{
            if(!validarDadosSenha()){
                return 0
            }
            let result = await service.redefinirSenha({
                "token":token,
                "senhaUsuario":senhaUsuario
            })
            if(result.status===200){
                toast.success("Senha alterada")
                props.history.push('/')
            }
        }   
        catch(error){
            //mostrarMensagem('Erro','error',error)
            //console.log(error.response.data.erro)
            toast.error(error.response.data.erro)
        }
    }

    const validarToken = ()=>{
        if(valido){
            return(
            <Container>
                <Card width="100%" height="50%" justifyContent="center">
                    <Img src={logo} display="flex" JustifyContent="center" AlignItems="center" width="calc(350px + 10%)"/>
                </Card>
                <Card width="100%" height="50%">
                    <Input placeholder='NOVA SENHA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
                    PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px"  bgcolor="#F2F2F2"
                    borderBottom="1px solid #C6C6C6" marginTop="50px" type="password" onKeyPress={props.keyEnter}
                    value={senhaUsuario} onChange={e=>{setSenhaUsuario(e.target.value)}}
                    />
                    <Input placeholder='CONFIRME SENHA' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="14px" PlaceletterSpacing="0.27em"
                    PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="14px"
                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" width="277px" height="25px" bgcolor="#F2F2F2"
                    borderBottom="1px solid #C6C6C6" marginTop="20px" type="password" onKeyPress={props.keyEnter}
                    value={confSenha} onChange={e=>{setConfSenha(e.target.value)}}
                    />
                    <Botoes fontFamily="Montserrat" bgcolor="#F2F2F2" borderBottom="1px solid #C6C6C6" TextAlign="center" marginTop="50px" 
                    width="140px" FontSize="14px" fontWight="300" color="#656565" letterSpacing="0.27em" lineHeight="17px" Activecolor="#3997EE"
                    ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>RedefinirASenha()}>
                        Alterar
                    </Botoes>
                </Card>
            </Container>
            )
        }
    }

    return(
        <Container>
            {validarToken()}
        </Container>
    )
}

export default RedefinirSenha