
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #F2F2F2;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;