import React from 'react'
import {Redirect,Route} from 'react-router-dom'
import { Auth_login_Adm } from '../utils'

const PrivateRouteAdm = ({ component:Component,...rest})=>{
    return(
        <Route {...rest} render={ props =>(
            Auth_login_Adm()?(<Component {...props}/>):
            <Redirect to ={{pathname:"/",state:{from:props.location}}}/>
        )}
        />
    )
}

export default PrivateRouteAdm