import React, { useEffect, useState } from 'react'
import { useTable } from "react-table";
import { Card, Container, Tabela , THead , Th, Tr, TBody, Td , Border , TFoot, CardHeader } from './styles';
import { Img, Input, Titulo , Botoes } from '../utilitarios';
import NextPageIcon from '../../imagens/NextPage.png'
import BackPageIcon from '../../imagens/BackPage.png'

const List = (props)=>{

    const [indexColorHover,setIndexColorHover]=useState(null)

    const OnHoverDiv = (seHover,index) =>{
        if(seHover){
            setIndexColorHover(index)
        }
        else{
            setIndexColorHover(null)
        }
    }

    const BorderPX = (index) =>{
        if(index===0){
            return "0.5px solid #C6C6C6"
        }
        else{
            return 0
        }
    }

    const ColorHover = (index) =>{
        if(indexColorHover===index){
            return "#E7E4E4"
        }
        else{
            return "white"
        }
    }

    const DesabilitarPaginacao = (flag) =>{
        if(flag){//Proxima Pagina
            if(props.paginaAtual===props.paginaFinal){
                return true
            }
            else{
                return false
            }
        }
        else if(!flag){//Pagina Anterior
            if(props.paginaAtual===1){
                return true
            }
            else{
                return false
            }
        }
    }

    const MudarPagina = (flag)=>{
        if(flag){//Proxima Pagina
            if(!DesabilitarPaginacao(flag)){
                props.setOffset(props.offset + 6)
                props.setPaginaAtual(props.paginaAtual + 1)
            }
        }
        else if(!flag){//Pagina Anterior
            if(!DesabilitarPaginacao(flag)){
                props.setOffset(props.offset - 6)
                props.setPaginaAtual(props.paginaAtual -1)
            }
        }
    }

    const OpacidadeDesabilitado = (flag)=>{
        if(DesabilitarPaginacao(flag)){
            return "0.5"
        }
        else if(!DesabilitarPaginacao(flag)){
            return ""
        }
    }

    const ClicouElementoTabela = (id)=>{
        props.setAbrirCrud(id)
    }

    const gerarLinhas = props.data.map((Obj,index)=>{
        if(props.isCrudUsuario){
            return(
                <Tr key={Obj.id} onClick={e=>ClicouElementoTabela(Obj.id)}>
                    <Card width="100%" flexDirection="column"  height="100%">
                        <Card width="100%" height="1%"> 
                            <Border borderTop={BorderPX(index)}/>
                        </Card>
                        <Card width="100%" flexDirection="row" height="100%" onMouseEnter={() => OnHoverDiv(true,index)}
                            Hoverbgcolor={ColorHover(index)} onMouseLeave={() => OnHoverDiv(false,index)} cursor="pointer">
                            <Card width="50%">
                                <Td>
                                    <Input type="text" defaultValue={Obj.usuario} readOnly width="100%"
                                    fontFamily="Montserrat" fontWight="300" fontSize="13px" bgcolor={ColorHover(index)}
                                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" cursor="pointer"/>
                                </Td>
                            </Card>
                            <Card width="50%">
                                <Td>
                                    <Input type="text" defaultValue={Obj.nome} readOnly width="100%" 
                                    fontFamily="Montserrat" fontWight="300" fontSize="13px" bgcolor={ColorHover(index)}
                                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" cursor="pointer"/>
                                </Td>
                            </Card>
                        </Card>
                        <Card width="100%" height="1%"> 
                            <Border borderBottom="0.25px solid #C6C6C6"/>
                        </Card>
                    </Card>
                </Tr>
            )
        }
        else{
            return(
                <Tr key={Obj.id} onClick={e=>ClicouElementoTabela(Obj.id)}>
                    <Card width="100%" flexDirection="column"  height="100%">
                        <Card width="100%" height="1%"> 
                            <Border borderTop={BorderPX(index)}/>
                        </Card>
                        <Card width="100%" flexDirection="row" height="100%" onMouseEnter={() => OnHoverDiv(true,index)}
                            Hoverbgcolor={ColorHover(index)} onMouseLeave={() => OnHoverDiv(false,index)} cursor="pointer">
                            <Card width="50%">
                                <Td>
                                    <Input type="text" defaultValue={Obj.id} readOnly width="100%"
                                    fontFamily="Montserrat" fontWight="300" fontSize="13px" bgcolor={ColorHover(index)}
                                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" cursor="pointer"/>
                                </Td>
                            </Card>
                            <Card width="50%">
                                <Td>
                                    <Input type="text" defaultValue={Obj.nome} readOnly width="100%" 
                                    fontFamily="Montserrat" fontWight="300" fontSize="13px" bgcolor={ColorHover(index)}
                                    letterSpacing="0.27em" lineHeight="17px" TextAlign="center" Color="#656565" cursor="pointer"/>
                                </Td>
                            </Card>
                        </Card>
                        <Card width="100%" height="1%"> 
                            <Border borderBottom="0.25px solid #C6C6C6"/>
                        </Card>
                    </Card>
                </Tr>
            )
        }
    })

    return(
        <Container>
            <CardHeader height="8%" width="95%">
                <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                FontWeight="bold" borderBottom="0.25px solid #C6C6C6">
                    {props.nomePagina}
                </Titulo>
            </CardHeader>
            <Tabela>
                <THead>
                    <Tr>
                        <Card width="50%">  
                            <Th>
                                {props.coluna1}
                            </Th>
                        </Card>
                        <Card width="50%">
                            <Th>
                                {props.coluna2}
                            </Th>
                        </Card>
                    </Tr>
                </THead>
                <TBody>
                    {gerarLinhas}
                </TBody>
                <TFoot>
                    <Img src={BackPageIcon} width="11px" height="14px" onClick={e=>MudarPagina(false)} 
                     cursor="pointer" Activeopacity="0.5" opacity={OpacidadeDesabilitado(false)}/>
                    <Titulo TextAlign="center" FontSize="12px" FontFamily="Montserrat" color="#C6C6C6" letterSpacing="0.17em" lineHeight="17px" 
                    FontWeight="bold" paddingLeft="10px" paddingRight="10px">
                        {props.paginaAtual}/{props.paginaFinal}
                    </Titulo>
                    <Img src={NextPageIcon} width="11px" height="14px" onClick={e=>MudarPagina(true)} 
                     cursor="pointer" Activeopacity="0.5" opacity={OpacidadeDesabilitado(true)}/>
                </TFoot>
            </Tabela>
            <Card height="5%">
                <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center" 
                width="80px" FontSize="14px" fontWight="300" color="#656565" letterSpacing="0.17em" lineHeight="17px" Activecolor="#3997EE"
                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>ClicouElementoTabela(null)} marginBottom="10%">
                    CRIAR
                </Botoes>
            </Card>
        </Container>
    )
}

export default List