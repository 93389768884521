import React, { useEffect, useState } from 'react'
import ConnectContaBI from '../../apis/adm/contabi'
import List from '../../componentes/lista'
import { Container , Card } from './styles'
import toast from 'react-hot-toast';
import CRUDContaBI from '../../componentes/crud-contabi';


const ContaBI = (props)=>{

    const service = new ConnectContaBI()
    const [contaBI,setContaBI]=useState([])
    const [offset,setOffset]=useState(0)
    const [paginaAtual,setPaginaAtual]=useState(1)
    const [maximoPaginas,setMaximoPaginas]=useState(1)
    const [idContaBI,setIdContaBI]=useState(-1)
    const [abrirComponente,setAbrirComponente]=useState(false)
    const [id,setId]=useState("")
    const [nome,setNome]=useState("")
    const [usuarioBI,setUsuarioBI]=useState("")
    const [senhaBI,setSenhaBI]=useState("")
    const [clienteIDBI,setClienteIDBI]=useState("")
    const [nomeOperacao,setNomeOperacao]=useState("")

    useEffect(async()=>{
        if(props.paginaAtiva===null){
            window.location.href="/adm"
            return 0
        }
        await ConsultarContaBI()
    },[])

    useEffect(async()=>{
        setContaBI([])
        await ConsultarContaBI()
    },[offset])

    useEffect(()=>{
        if(paginaAtual>maximoPaginas){
            setPaginaAtual(1)
            setOffset(0)
        }
    },[maximoPaginas])

    useEffect(()=>{
        if(idContaBI===null){
            setId("")
            setNome("")
            setUsuarioBI("")
            setSenhaBI("")
            setClienteIDBI("")
            setNomeOperacao("INSERÇÂO")
            setAbrirComponente(true)
            return 0
        }
        for(let i in contaBI){
            if(contaBI[i].id === idContaBI){
                setId(contaBI[i].id)
                setNome(contaBI[i].nome)
                setUsuarioBI(contaBI[i].usuariobi)
                setSenhaBI(contaBI[i].senhabi)
                setClienteIDBI(contaBI[i].clientidbi)
                setNomeOperacao("ALTERAÇÃO")
                setAbrirComponente(true)
                return 0
            }
        }
    },[idContaBI])

    const validarInfos = () =>{
        if(nome===""){
            toast.error("Informe o nome da conta BI")
            return false
        }
        if(usuarioBI===""){
            toast.error("Informe o usuário da conta BI")
            return false
        }
        if(senhaBI===""){
            toast.error("Informe a senha da conta BI")
            return false
        }
        if(clienteIDBI===""){
            toast.error("Informe o client ID da conta BI")
            return false
        }
        return true
    }

    const ConsultarContaBI = async()=>{
        try{
            let url = `idcontabi=null&nomecontabi=null&usuariobi=null&senhabi=null&clientidbi=null&ativo=true&offset=`+offset
            let result = await service.consultarContaBI(url)
            if(result.status===200){
                setContaBI([])
                let arrendondar = Math.ceil(result.data.count/6);
                if(arrendondar===0){
                    arrendondar=1
                }
                setMaximoPaginas(arrendondar)
                setContaBI(result.data.data)
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CriarContaBI = async()=>{
        try{
            if(validarInfos()){
                let result = await service.criarContaBI({
                    "nomecontabi":nome,
                    "usuariobi":usuarioBI,
                    "senhabi":senhaBI,
                    "clientidbi":clienteIDBI
                })
                if(result.status===201){
                    toast.success("Inserido nova area de negocio")
                    await ConsultarContaBI()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const DeletarContaBI = async()=>{
        try{
            if(validarInfos()){
                let url = "idcontabi="+id
                let result = await service.deleteContaBI(url)
                if(result.status===200){
                    toast.success("Deletado area de negocio")
                    await ConsultarContaBI()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const AlterarContaBI = async()=>{
        try{
            if(validarInfos()){
                let result = await service.alterarContaBI({
                    "idcontabi":id,
                    "nomecontabi":nome,
                    "usuariobi":usuarioBI,
                    "senhabi":senhaBI,
                    "clientidbi":clienteIDBI
                })
                if(result.status===200){
                    toast.success("Alterado area de negocio")
                    await ConsultarContaBI()
                    CancelarOperacao()
                }
            }
        }
        catch(error){
            toast.error(error.response.data.msg)
        }
    }

    const CancelarOperacao = () =>{
        setAbrirComponente(false)
        setId("")
        setNome("")
        setNomeOperacao("")
        setIdContaBI(-1)
    }


    return(
        <Container>
            <Card>
                <List data={contaBI} isCrudUsuario={false} coluna1="ID" coluna2="NOME" nomePagina="CONTA BI"
                  paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} 
                  paginaFinal={maximoPaginas} offset={offset} setOffset={setOffset}
                  setAbrirCrud={setIdContaBI} />
            </Card>
            <Card>
                {abrirComponente &&(
                    <CRUDContaBI id={id} setId={setId} nomeOperacao={nomeOperacao}
                     nome={nome} setNome={setNome} 
                     usuarioBI={usuarioBI} setUsuarioBI={setUsuarioBI}
                     senhaBI={senhaBI} setSenhaBI={setSenhaBI}
                     clienteIDBI={clienteIDBI} setClienteIDBI={setClienteIDBI}
                     CancelarOperacao={CancelarOperacao} AlterarContaBI={AlterarContaBI}
                     DeletarContaBI={DeletarContaBI} CriarContaBI={CriarContaBI}/>
                )}
            </Card>
        </Container>
    )
}

export default ContaBI