import React , { useState } from 'react'
import { Container , Card , CardBotoes , Grupo  } from './styles'
import { Botoes , Input , Titulo } from '../utilitarios'

const CRUDAreaNegocio = (props)=>{

    const seInserir = () =>{
        if(props.id===""){
            return (
                <Card width="100%">
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CriarAreaNegocio()} height="20px">
                                INSERIR
                        </Botoes>
                    </Grupo>
                    <Grupo>
                        <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                            width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                            ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.CancelarOperacao()} height="20px">
                                CANCELAR
                        </Botoes>
                    </Grupo>
                </Card>
                )
        }
        else {
            return (
                    <Card width="100%">
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="green" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.AlterarAreaNegocio()} height="20px">
                                    ALTERAR
                            </Botoes>
                        </Grupo>
                        <Grupo>
                            <Botoes fontFamily="Montserrat" bgcolor="white" borderBottom="1px solid #C6C6C6" TextAlign="center"
                                width="120px" FontSize="14px" fontWight="500" color="red" letterSpacing="0.17em" lineHeight="13px" Activecolor="#3997EE"
                                ActiveFontWeight="bold" HoverFontWeight="bold" onClick={e=>props.DeletarAreaNegocio()} height="20px">
                                    EXCLUIR
                            </Botoes>
                        </Grupo>
                    </Card>
                    )
        }
    }

    return(
        <Container width="100%" height="100%">
            <Container  width="100%" height="5%">
                <Titulo TextAlign="center" FontSize="19px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                    FontWeight="bold"  borderBottom="0.25px solid #C6C6C6">
                    {props.nomeOperacao}
                </Titulo>
            </Container>
            <Container width="100%" height="75%">
                <Card width="100%" marginBottom="20%">
                    <Grupo>
                        <Titulo TextAlign="center" FontSize="14px" FontFamily="Montserrat" color="#313131" letterSpacing="0.17em" lineHeight="17px" 
                            FontWeight="bold">
                            AREA DE NEGÓCIO
                        </Titulo>
                    </Grupo>
                    <Grupo>
                        <Input placeholder='AREA NEGOCIO' PlacefontFamily="Montserrat" PlacefontWight="300" PlacefontSize="12px" PlaceletterSpacing="0.17em"
                        PlacelineHeight="17px" PlaceTextAlign="center" PlaceColor="#C6C6C6" fontFamily="Montserrat" fontWight="300" fontSize="16px"
                        letterSpacing="0.17em" lineHeight="17px" TextAlign="center" Color="#656565" width="calc(70% + 50px);" height="25px" bgcolor="WHITE"
                        borderBottom="1px solid #C6C6C6" type="text" onKeyPress={props.keyEnter}
                        value={props.nome} onChange={e=>{props.setNome(e.target.value)}}
                        />
                    </Grupo>                
                </Card>
            </Container>
            <Container  width="100%" height="50%">
                {seInserir()}
            </Container>
            
        </Container>
    )
}

export default CRUDAreaNegocio