import styled from "styled-components";

export const Container = styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    margin-top: 2%;
`;

export const Card = styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    margin-top: ${props=>props.marginTop};
    margin-bottom: ${props=>props.marginBottom};;
    @media(max-width: 1100px) {
        display: flex;
        flex-direction: column;
    } 
`

export const Grupo = styled.div`
    width: 50%;
    height: ${props=>props.height};
    display: flex;
    justify-content:center;
    align-items:center;
    @media(max-width: 1100px) {
        display: flex;
        flex-direction: column;
        margin-top:2%;
    } 
`