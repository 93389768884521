import ApiService from "../../index";

class ConnectAreaNegocio extends ApiService{
    constructor(){
        super('/api/v1/adm/areanegocio')
    }
    
    async consultarAreaNegocio(query){
        return await this.get("/?"+query)
    }

    async criarAreaNegocio(obj){
        return await this.post("/",obj)
    }

    async alterarAreaNegocio(obj){
        return await this.put("/",obj)
    }

    async deleteAreaNegocio(query){
        return await this.delete("/?"+query)
    }

    async listAreaNegocio(query){
        return await this.get("/list?"+query)
    }

}

export default ConnectAreaNegocio