import React, { useState } from 'react'
import { getAreas, getAreaPaineis } from '../../utils'
import { Botoes, Titulo } from '../utilitarios'
import { Container, Card } from './styles'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import MenuPaineis from '../menu-paineis';

const SelectHomeClienteAdmcliente = (props)=>{

    const [areas]=useState(getAreaPaineis())

    const rows = areas.map(Obj =>{
        return(
            <Card>
                <MenuPaineis id={Obj.id} desc={Obj.desc} paineis={Obj.paineis}
                 gerarTokenEmbedded={props.gerarTokenEmbedded}
                 areaAtiva={props.areaAtiva} painelAtivo={props.painelAtivo}/>
            </Card>
        )
    })


    return(
        <Container>
            {rows}
        </Container>
    )
}

export default SelectHomeClienteAdmcliente